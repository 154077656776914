import * as _classnames2 from "classnames";

var _classnames = "default" in _classnames2 ? _classnames2.default : _classnames2;

import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

import * as _axios2 from "axios";

var _axios = "default" in _axios2 ? _axios2.default : _axios2;

import * as _reactTransitionGroup2 from "react-transition-group";

var _reactTransitionGroup = "default" in _reactTransitionGroup2 ? _reactTransitionGroup2.default : _reactTransitionGroup2;

var exports = {};

function e(e) {
  return e && "object" == typeof e && "default" in e ? e.default : e;
}

Object.defineProperty(exports, "__esModule", {
  value: !0
});

var t = e(_classnames),
    n = _react,
    r = e(n),
    a = e(_axios),
    o = _reactTransitionGroup,
    i = function (e, t) {
  for (var n = 0, r = []; n < e.length;) r.push(e.slice(n, n += t));

  return r;
},
    u = new (function () {
  function e() {
    this.listeners = {};
  }

  var t = e.prototype;
  return t.on = function (e, t) {
    var n = this,
        r = this.listeners[e];
    return r || (r = []), r.push(t), this.listeners[e] = r, function () {
      n.remove(e, t);
    };
  }, t.emit = function (e) {
    var t = this.listeners[e];

    if (Array.isArray(t)) {
      for (var n = arguments.length, r = new Array(n > 1 ? n - 1 : 0), a = 1; a < n; a++) r[a - 1] = arguments[a];

      for (var o = 0; o < t.length; o++) {
        var i = t[o];
        "function" == typeof i && i.apply(void 0, r);
      }
    }
  }, t.remove = function (e, t) {
    if (t) {
      var n = this.listeners[e];
      if (!n) return;
      n = n.filter(function (e) {
        return e !== t;
      }), this.listeners[e] = n;
    } else this.listeners[e] = null, delete this.listeners[e];
  }, e;
}())(),
    l = n.createContext({
  color: "",
  modeList: [],
  handApi: "",
  transitionTime: 0,
  closeKeyBoard: function () {},
  changeDefaultBoard: function () {}
}),
    c = l.Provider,
    s = n.memo(function (e) {
  var t = e.resultVal,
      a = e.change,
      o = n.useContext(l).color,
      c = n.useState(""),
      s = c[0],
      d = c[1],
      h = n.useState([]),
      f = h[0],
      m = h[1],
      p = n.useState([]),
      g = p[0],
      y = p[1],
      v = n.useState(0),
      k = v[0],
      w = v[1],
      E = {
    borderTopColor: o
  };

  function b() {
    w(0), m([]), y([]), u.emit("resultReset");
  }

  return n.useEffect(function () {
    return u.on("keyBoardChange", function (e) {
      u.emit("updateBound"), d(e), b();
    }), u.on("getWordsFromServer", function (e) {
      var t = Array.from(new Set(e.replace(/\s+/g, "").split("")));
      m(t), y(i(t, 11));
    }), function () {
      u.remove("keyBoardChange"), u.remove("getWordsFromServer");
    };
  }, []), n.useEffect(function () {
    var e;
    w(0);
    var n = (null == t || null == (e = t.value) ? void 0 : e.split("")) || [];
    m(n), y(0 !== n.length ? i(n, 11) : []);
  }, [t]), "CN" === s || "handwrite" === s ? r.createElement("div", {
    className: "key-board-result",
    style: {
      color: o
    }
  }, "CN" === s && r.createElement("div", {
    className: "key-board-code-show"
  }, t.code), r.createElement("div", {
    className: "key-board-result-show"
  }, r.createElement("div", {
    className: "key-board-result-show-container"
  }, g.length > 0 && g[k].map(function (e, t) {
    return r.createElement("span", {
      key: t,
      onClick: function () {
        b(), a && a(e);
      }
    }, t + 1, ".", e);
  })), f.length > 11 && r.createElement("div", {
    className: "key-board-result-show-more"
  }, r.createElement("span", {
    style: E,
    onClick: function () {
      0 !== k && w(k - 1);
    }
  }), r.createElement("span", {
    style: E,
    onClick: function () {
      k !== g.length - 1 && w(k + 1);
    }
  })))) : r.createElement("div", null);
});

function d(e) {
  return n.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 58.6 35.1"
  }, e), n.createElement("defs", null, n.createElement("style", null, ".st0{stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}.st1{stroke-width:2.1499;stroke-miterlimit:10;}.st2{stroke-width:3.2571;stroke-miterlimit:10;}")), n.createElement("g", null, n.createElement("path", {
    className: "st0",
    d: "M8.4,8.8h37.1c6.7,0,12.2,5.4,12.2,12.2l0,0c0,7.2-5.8,13.1-13.1,13.1c0,0,0,0,0,0h-24"
  }), n.createElement("g", null, n.createElement("path", {
    className: "st1",
    d: "M3,9.1l8.6,6.9c0.1,0.1,0.3,0,0.3-0.1V2c0-0.2-0.2-0.2-0.3-0.1L3,8.8C2.9,8.9,2.9,9,3,9.1z"
  }), n.createElement("path", {
    className: "st2",
    d: "M10.4,4.8c0,0-5.4,4.1-5.4,4s5.6,3.8,5.6,3.8L10.4,4.8z"
  }))));
}

function h(e) {
  return n.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 50 35.93"
  }, e), n.createElement("defs", null, n.createElement("style", null, ".cls-2,.cls-3,.cls-4{stroke-width:2px;}.cls-3,.cls-4{stroke-linecap:round;}.cls-3{stroke-dasharray:3 4;}.cls-5,.cls-6{stroke-miterlimit:10;}.cls-5{stroke-width:2.2px;}.cls-6{stroke-width:3.39px;}")), n.createElement("g", null, n.createElement("g", null, n.createElement("g", null, n.createElement("g", null, n.createElement("rect", {
    className: "cls-1",
    width: "50",
    height: "27",
    rx: "5"
  }), n.createElement("rect", {
    className: "cls-2",
    x: "1",
    y: "1",
    width: "48",
    height: "25",
    rx: "4"
  })), n.createElement("g", null, n.createElement("path", {
    className: "cls-3",
    d: "M9.5,8H40.18"
  }), n.createElement("path", {
    className: "cls-3",
    d: "M9.5,13.5H40.18"
  }), n.createElement("path", {
    className: "cls-3",
    d: "M9.5,19h3.17"
  }), n.createElement("path", {
    className: "cls-4",
    d: "M16.5,19h17"
  }), n.createElement("path", {
    className: "cls-3",
    d: "M37.5,19h2.68"
  }))), n.createElement("path", {
    className: "cls-5",
    d: "M25.18,34.82l5.32-4.25a.07.07,0,0,0,0-.12H19.83a.07.07,0,0,0,0,.12l5.31,4.25A.06.06,0,0,0,25.18,34.82Z"
  }), n.createElement("circle", {
    className: "cls-6",
    cx: "25.14",
    cy: "32.58",
    r: "1"
  }))));
}

function f(e) {
  return n.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    width: 24.37,
    height: 32.991,
    viewBox: "0 0 24.37 32.991"
  }, e), n.createElement("g", {
    transform: "translate(-437.841 -757.875)"
  }, n.createElement("path", {
    d: "M800.491,472.525l-9.622-9.889a1.53,1.53,0,0,0-2.192,0l-9.622,9.889a1.529,1.529,0,0,0,1.1,2.6h3.975a1.529,1.529,0,0,1,1.529,1.529v8.927a1.529,1.529,0,0,0,1.529,1.529h5.175a1.529,1.529,0,0,0,1.529-1.529V476.65a1.529,1.529,0,0,1,1.529-1.529h3.976A1.529,1.529,0,0,0,800.491,472.525Z",
    transform: "translate(-339.747 296.701)",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    strokeWidth: "2"
  }), n.createElement("line", {
    x2: "13.938",
    transform: "translate(442.92 789.865)",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    strokeWidth: "2"
  })));
}

function m(e) {
  return n.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    width: 66.467,
    height: 28.8,
    viewBox: "0 0 66.467 28.8"
  }, e), n.createElement("g", {
    transform: "translate(-1618 -633)"
  }, n.createElement("path", {
    d: "M842.844,477.922l-10.988,8.855a4.545,4.545,0,0,0,0,7.078l10.988,8.855a4.545,4.545,0,0,0,2.852,1.006h44.388a4.545,4.545,0,0,0,4.546-4.545v-17.71a4.545,4.545,0,0,0-4.546-4.545H845.7A4.545,4.545,0,0,0,842.844,477.922Z",
    transform: "translate(788.837 157.084)",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    strokeWidth: "2"
  }), n.createElement("line", {
    x2: "7.743",
    y2: "7.743",
    transform: "translate(1651.233 644.027)",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    strokeWidth: "2"
  }), n.createElement("line", {
    x1: "7.743",
    y2: "7.743",
    transform: "translate(1651.233 644.027)",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    strokeWidth: "2"
  })));
}

function p(e) {
  return n.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    width: 24.784,
    height: 33.44,
    viewBox: "0 0 24.784 33.44"
  }, e), n.createElement("g", {
    transform: "translate(-783.997 -761.616)"
  }, n.createElement("rect", {
    width: 7.324,
    height: 23.712,
    rx: 1.136,
    transform: "rotate(33.07 -892.505 1727.373)"
  }), n.createElement("rect", {
    width: 7.324,
    height: 4.946,
    rx: 1.136,
    transform: "rotate(33.07 -884.183 1729.853)"
  }), n.createElement("path", {
    d: "M785.413 788.854l-.407 3.922a1.136 1.136 0 001.693 1.1l3.425-1.953a1.137 1.137 0 00.057-1.939l-3.017-1.968a1.137 1.137 0 00-1.751.838z"
  })));
}

var g = n.memo(function (e) {
  var a = n.useState(!1),
      o = a[0],
      i = a[1],
      u = n.useContext(l).color;
  return r.createElement("button", {
    className: t("key-board-button", "key-board-button-" + e.type, {
      "key-board-button-active": e.isUpper && "upper" === e.type || e.isNum && "change2num" === e.type || e.isSymbol && "#+=" === e.type
    }),
    style: e.isUpper && "upper" === e.type || e.isNum && "change2num" === e.type || e.isSymbol && "#+=" === e.type || o ? {
      color: "#f5f5f5",
      background: u
    } : {
      color: u,
      background: "#f5f5f5"
    },
    onClick: function (t) {
      t.preventDefault(), e.click && e.click({
        data: e.isUpper ? e.data.toUpperCase() : e.data,
        type: e.type
      });
    },
    onMouseEnter: function () {
      i(!0);
    },
    onMouseLeave: function () {
      i(!1);
    }
  }, "back" === e.type ? r.createElement(d, {
    fill: "none",
    stroke: u
  }) : "close" === e.type ? r.createElement(h, {
    fill: "none",
    stroke: u
  }) : "handwrite" === e.type ? r.createElement(p, {
    fill: "none",
    stroke: u
  }) : "delete" === e.type ? r.createElement(m, {
    fill: "none",
    stroke: u
  }) : "upper" === e.type ? r.createElement(f, {
    fill: "none",
    stroke: u
  }) : r.createElement("span", {
    dangerouslySetInnerHTML: "change2lang" === e.type ? {
      __html: e.isCn ? "<label>\u4E2D</label>/EN" : "<label>EN</label>/\u4E2D"
    } : {
      __html: e.isUpper ? e.data.toUpperCase() : e.data
    }
  }));
});

function y(e, t, n, r, a, o, i) {
  try {
    var u = e[o](i),
        l = u.value;
  } catch (e) {
    return void n(e);
  }

  u.done ? t(l) : Promise.resolve(l).then(r, a);
}

function v(e) {
  return function () {
    var t = this,
        n = arguments;
    return new Promise(function (r, a) {
      var o = e.apply(t, n);

      function i(e) {
        y(o, r, a, i, u, "next", e);
      }

      function u(e) {
        y(o, r, a, i, u, "throw", e);
      }

      i(void 0);
    });
  };
}

var k,
    w,
    E = (function (e) {
  var t = function (e) {
    var t = Object.prototype,
        n = t.hasOwnProperty,
        r = "function" == typeof Symbol ? Symbol : {},
        a = r.iterator || "@@iterator",
        o = r.asyncIterator || "@@asyncIterator",
        i = r.toStringTag || "@@toStringTag";

    function u(e, t, n) {
      return Object.defineProperty(e, t, {
        value: n,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }), e[t];
    }

    try {
      u({}, "");
    } catch (e) {
      u = function (e, t, n) {
        return e[t] = n;
      };
    }

    function l(e, t, n, r) {
      var a = Object.create((t && t.prototype instanceof d ? t : d).prototype),
          o = new x(r || []);
      return a._invoke = function (e, t, n) {
        var r = "suspendedStart";
        return function (a, o) {
          if ("executing" === r) throw new Error("Generator is already running");

          if ("completed" === r) {
            if ("throw" === a) throw o;
            return {
              value: void 0,
              done: !0
            };
          }

          for (n.method = a, n.arg = o;;) {
            var i = n.delegate;

            if (i) {
              var u = w(i, n);

              if (u) {
                if (u === s) continue;
                return u;
              }
            }

            if ("next" === n.method) n.sent = n._sent = n.arg;else if ("throw" === n.method) {
              if ("suspendedStart" === r) throw r = "completed", n.arg;
              n.dispatchException(n.arg);
            } else "return" === n.method && n.abrupt("return", n.arg);
            r = "executing";
            var l = c(e, t, n);

            if ("normal" === l.type) {
              if (r = n.done ? "completed" : "suspendedYield", l.arg === s) continue;
              return {
                value: l.arg,
                done: n.done
              };
            }

            "throw" === l.type && (r = "completed", n.method = "throw", n.arg = l.arg);
          }
        };
      }(e, n, o), a;
    }

    function c(e, t, n) {
      try {
        return {
          type: "normal",
          arg: e.call(t, n)
        };
      } catch (e) {
        return {
          type: "throw",
          arg: e
        };
      }
    }

    e.wrap = l;
    var s = {};

    function d() {}

    function h() {}

    function f() {}

    var m = {};

    m[a] = function () {
      return this;
    };

    var p = Object.getPrototypeOf,
        g = p && p(p(L([])));
    g && g !== t && n.call(g, a) && (m = g);
    var y = f.prototype = d.prototype = Object.create(m);

    function v(e) {
      ["next", "throw", "return"].forEach(function (t) {
        u(e, t, function (e) {
          return this._invoke(t, e);
        });
      });
    }

    function k(e, t) {
      var r;

      this._invoke = function (a, o) {
        function i() {
          return new t(function (r, i) {
            !function r(a, o, i, u) {
              var l = c(e[a], e, o);

              if ("throw" !== l.type) {
                var s = l.arg,
                    d = s.value;
                return d && "object" == typeof d && n.call(d, "__await") ? t.resolve(d.__await).then(function (e) {
                  r("next", e, i, u);
                }, function (e) {
                  r("throw", e, i, u);
                }) : t.resolve(d).then(function (e) {
                  s.value = e, i(s);
                }, function (e) {
                  return r("throw", e, i, u);
                });
              }

              u(l.arg);
            }(a, o, r, i);
          });
        }

        return r = r ? r.then(i, i) : i();
      };
    }

    function w(e, t) {
      var n = e.iterator[t.method];

      if (void 0 === n) {
        if (t.delegate = null, "throw" === t.method) {
          if (e.iterator.return && (t.method = "return", t.arg = void 0, w(e, t), "throw" === t.method)) return s;
          t.method = "throw", t.arg = new TypeError("The iterator does not provide a 'throw' method");
        }

        return s;
      }

      var r = c(n, e.iterator, t.arg);
      if ("throw" === r.type) return t.method = "throw", t.arg = r.arg, t.delegate = null, s;
      var a = r.arg;
      return a ? a.done ? (t[e.resultName] = a.value, t.next = e.nextLoc, "return" !== t.method && (t.method = "next", t.arg = void 0), t.delegate = null, s) : a : (t.method = "throw", t.arg = new TypeError("iterator result is not an object"), t.delegate = null, s);
    }

    function E(e) {
      var t = {
        tryLoc: e[0]
      };
      1 in e && (t.catchLoc = e[1]), 2 in e && (t.finallyLoc = e[2], t.afterLoc = e[3]), this.tryEntries.push(t);
    }

    function b(e) {
      var t = e.completion || {};
      t.type = "normal", delete t.arg, e.completion = t;
    }

    function x(e) {
      this.tryEntries = [{
        tryLoc: "root"
      }], e.forEach(E, this), this.reset(!0);
    }

    function L(e) {
      if (e) {
        var t = e[a];
        if (t) return t.call(e);
        if ("function" == typeof e.next) return e;

        if (!isNaN(e.length)) {
          var r = -1,
              o = function t() {
            for (; ++r < e.length;) if (n.call(e, r)) return t.value = e[r], t.done = !1, t;

            return t.value = void 0, t.done = !0, t;
          };

          return o.next = o;
        }
      }

      return {
        next: N
      };
    }

    function N() {
      return {
        value: void 0,
        done: !0
      };
    }

    return h.prototype = y.constructor = f, f.constructor = h, h.displayName = u(f, i, "GeneratorFunction"), e.isGeneratorFunction = function (e) {
      var t = "function" == typeof e && e.constructor;
      return !!t && (t === h || "GeneratorFunction" === (t.displayName || t.name));
    }, e.mark = function (e) {
      return Object.setPrototypeOf ? Object.setPrototypeOf(e, f) : (e.__proto__ = f, u(e, i, "GeneratorFunction")), e.prototype = Object.create(y), e;
    }, e.awrap = function (e) {
      return {
        __await: e
      };
    }, v(k.prototype), k.prototype[o] = function () {
      return this;
    }, e.AsyncIterator = k, e.async = function (t, n, r, a, o) {
      void 0 === o && (o = Promise);
      var i = new k(l(t, n, r, a), o);
      return e.isGeneratorFunction(n) ? i : i.next().then(function (e) {
        return e.done ? e.value : i.next();
      });
    }, v(y), u(y, i, "Generator"), y[a] = function () {
      return this;
    }, y.toString = function () {
      return "[object Generator]";
    }, e.keys = function (e) {
      var t = [];

      for (var n in e) t.push(n);

      return t.reverse(), function n() {
        for (; t.length;) {
          var r = t.pop();
          if (r in e) return n.value = r, n.done = !1, n;
        }

        return n.done = !0, n;
      };
    }, e.values = L, x.prototype = {
      constructor: x,
      reset: function (e) {
        if (this.prev = 0, this.next = 0, this.sent = this._sent = void 0, this.done = !1, this.delegate = null, this.method = "next", this.arg = void 0, this.tryEntries.forEach(b), !e) for (var t in this) "t" === t.charAt(0) && n.call(this, t) && !isNaN(+t.slice(1)) && (this[t] = void 0);
      },
      stop: function () {
        this.done = !0;
        var e = this.tryEntries[0].completion;
        if ("throw" === e.type) throw e.arg;
        return this.rval;
      },
      dispatchException: function (e) {
        if (this.done) throw e;
        var t = this;

        function r(n, r) {
          return i.type = "throw", i.arg = e, t.next = n, r && (t.method = "next", t.arg = void 0), !!r;
        }

        for (var a = this.tryEntries.length - 1; a >= 0; --a) {
          var o = this.tryEntries[a],
              i = o.completion;
          if ("root" === o.tryLoc) return r("end");

          if (o.tryLoc <= this.prev) {
            var u = n.call(o, "catchLoc"),
                l = n.call(o, "finallyLoc");

            if (u && l) {
              if (this.prev < o.catchLoc) return r(o.catchLoc, !0);
              if (this.prev < o.finallyLoc) return r(o.finallyLoc);
            } else if (u) {
              if (this.prev < o.catchLoc) return r(o.catchLoc, !0);
            } else {
              if (!l) throw new Error("try statement without catch or finally");
              if (this.prev < o.finallyLoc) return r(o.finallyLoc);
            }
          }
        }
      },
      abrupt: function (e, t) {
        for (var r = this.tryEntries.length - 1; r >= 0; --r) {
          var a = this.tryEntries[r];

          if (a.tryLoc <= this.prev && n.call(a, "finallyLoc") && this.prev < a.finallyLoc) {
            var o = a;
            break;
          }
        }

        o && ("break" === e || "continue" === e) && o.tryLoc <= t && t <= o.finallyLoc && (o = null);
        var i = o ? o.completion : {};
        return i.type = e, i.arg = t, o ? (this.method = "next", this.next = o.finallyLoc, s) : this.complete(i);
      },
      complete: function (e, t) {
        if ("throw" === e.type) throw e.arg;
        return "break" === e.type || "continue" === e.type ? this.next = e.arg : "return" === e.type ? (this.rval = this.arg = e.arg, this.method = "return", this.next = "end") : "normal" === e.type && t && (this.next = t), s;
      },
      finish: function (e) {
        for (var t = this.tryEntries.length - 1; t >= 0; --t) {
          var n = this.tryEntries[t];
          if (n.finallyLoc === e) return this.complete(n.completion, n.afterLoc), b(n), s;
        }
      },
      catch: function (e) {
        for (var t = this.tryEntries.length - 1; t >= 0; --t) {
          var n = this.tryEntries[t];

          if (n.tryLoc === e) {
            var r = n.completion;

            if ("throw" === r.type) {
              var a = r.arg;
              b(n);
            }

            return a;
          }
        }

        throw new Error("illegal catch attempt");
      },
      delegateYield: function (e, t, n) {
        return this.delegate = {
          iterator: L(e),
          resultName: t,
          nextLoc: n
        }, "next" === this.method && (this.arg = void 0), s;
      }
    }, e;
  }(e.exports);

  try {
    regeneratorRuntime = t;
  } catch (e) {
    Function("r", "regeneratorRuntime = r")(t);
  }
}(w = {
  exports: {}
}), w.exports),
    b = function () {
  var e = v(E.mark(function e(t, n, r, o) {
    return E.wrap(function (e) {
      for (;;) switch (e.prev = e.next) {
        case 0:
          return e.next = 2, a.post("", {
            lib: o,
            lpXis: t,
            lpYis: n,
            lpCis: r
          });

        case 2:
          return e.abrupt("return", e.sent);

        case 3:
        case "end":
          return e.stop();
      }
    }, e);
  }));
  return function (t, n, r, a) {
    return e.apply(this, arguments);
  };
}(),
    x = !1,
    L = 0,
    N = 0,
    S = 0,
    C = 0,
    z = [],
    j = [],
    M = [],
    B = void 0,
    q = n.memo(function (e) {
  var t = n.useRef(null),
      a = n.useState(500),
      o = a[0],
      i = a[1],
      c = n.useState(500),
      s = c[0],
      d = c[1],
      h = n.useContext(l),
      f = h.color,
      m = h.transitionTime;

  function p() {
    var e;

    if (document.querySelector(".paint-board")) {
      var t = null == (e = document.querySelector(".paint-board")) ? void 0 : e.getBoundingClientRect();
      t && (L = t.x, N = t.y), i(parseFloat(window.getComputedStyle(document.querySelector(".paint-board")).width)), d(parseFloat(window.getComputedStyle(document.querySelector(".paint-board")).height));
    }
  }

  function g() {
    t && k && (z = [], j = [], M = [], k.clearRect(0, 0, o, s));
  }

  function y(e) {
    return e.type.includes("mouse") ? Math.floor(e.clientX - L) : e.type.includes("touch") ? Math.floor(e.targetTouches[0].clientX - L) : 0;
  }

  function w(e) {
    return e.type.includes("mouse") ? Math.floor(e.clientY - N) : e.type.includes("touch") ? Math.floor(e.targetTouches[0].clientY - N) : 0;
  }

  function q(e) {
    if (k) {
      x = !0;
      var t = y(e),
          n = w(e);
      B && clearTimeout(B), S = t, C = n, k.beginPath();
    }
  }

  function T(e) {
    if (k && (e.type.includes("mouse") && e.preventDefault(), x)) {
      var t = y(e),
          n = w(e);
      z.push(t), j.push(n), M.push(0), k.strokeStyle = f, k.fillStyle = f, k.lineWidth = 4, k.lineCap = "round", k.moveTo(S, C), k.lineTo(t, n), k.stroke(), S = t, C = n;
    }
  }

  function O() {
    x && (x = !1, B = setTimeout(function () {
      g();
    }, 1500), M.pop(), M.push(1), function () {
      _.apply(this, arguments);
    }());
  }

  function _() {
    return (_ = v(E.mark(function t() {
      var n;
      return E.wrap(function (t) {
        for (;;) switch (t.prev = t.next) {
          case 0:
            return t.next = 2, b(z, j, M, e.lib);

          case 2:
            u.emit("getWordsFromServer", (null == (n = t.sent.data) ? void 0 : n.v) || "");

          case 5:
          case "end":
            return t.stop();
        }
      }, t);
    }))).apply(this, arguments);
  }

  return n.useEffect(function () {
    var e;
    return k = null == (e = t.current) ? void 0 : e.getContext("2d"), g(), setTimeout(function () {
      p();
    }, m), window.addEventListener("transitionend", p), window.addEventListener("animationend", p), window.addEventListener("resize", p), window.addEventListener("scroll", p), u.on("updateBound", function () {
      p();
    }), function () {
      window.removeEventListener("transitionend", p), window.removeEventListener("animationend", p), window.removeEventListener("resize", p), window.removeEventListener("scroll", p), u.remove("updateBound");
    };
  }, []), r.createElement("div", {
    className: "paint-board"
  }, r.createElement("canvas", {
    ref: t,
    width: o,
    height: s,
    onTouchStart: q,
    onTouchMove: T,
    onTouchEnd: O,
    onMouseDown: q,
    onMouseMove: T,
    onMouseUp: O,
    onMouseLeave: O
  }));
}),
    T = n.memo(function (e) {
  var t = n.useContext(l),
      a = t.closeKeyBoard,
      o = t.changeDefaultBoard,
      i = n.useState(!0),
      c = i[0],
      s = i[1];
  return r.createElement("div", {
    className: "hand-write-board"
  }, r.createElement(q, {
    lib: c ? "CN" : "EN"
  }), r.createElement("div", {
    className: "hand-write-board-opers"
  }, [{
    data: "\u4E2D/EN",
    type: "change2lang"
  }, {
    data: "",
    type: "back"
  }, {
    data: "",
    type: "delete"
  }, {
    data: "",
    type: "close"
  }].map(function (t) {
    return r.createElement(g, {
      "v-for": "key in handBoardOperList",
      key: t.type,
      type: t.type,
      data: t.data,
      isCn: c,
      click: function (t) {
        var n = t.data,
            r = t.type;

        switch (r) {
          case "close":
            a();
            break;

          case "back":
            o(), u.emit("keyBoardChange", c && "CN");
            break;

          case "change2lang":
            s(!c);
            break;

          case "delete":
            (null == e ? void 0 : e.trigger) && (null == e || e.trigger({
              data: n,
              type: r
            }));
        }
      }
    });
  })));
}),
    O = {
  a: "\u963F\u554A\u5475\u814C\u55C4\u5416\u9515",
  e: "\u989D\u963F\u4FC4\u6076\u9E45\u904F\u9102\u5384\u997F\u5CE8\u627C\u5A25\u9CC4\u54E6\u86FE\u5669\u6115\u8BB9\u9537\u57A9\u5A40\u9E57\u843C\u8C14\u83AA\u816D\u9507\u989A\u5443\u960F\u5C59\u82CA\u8F6D",
  ai: "\u7231\u57C3\u827E\u788D\u764C\u54C0\u6328\u77EE\u9698\u853C\u5509\u7691\u54CE\u972D\u6371\u66A7\u5AD2\u55F3\u7477\u55CC\u953F\u7839",
  ei: "\u8BF6",
  xi: "\u7CFB\u897F\u5E2D\u606F\u5E0C\u4E60\u5438\u559C\u7EC6\u6790\u620F\u6D17\u6089\u9521\u6EAA\u60DC\u7A00\u88AD\u5915\u6D12\u6670\u6614\u727A\u814A\u70EF\u7199\u5AB3\u6816\u819D\u9699\u7280\u8E4A\u7852\u516E\u7184\u66E6\u79A7\u5B09\u73BA\u595A\u6C50\u5F99\u7FB2\u94E3\u6DC5\u563B\u6B59\u71B9\u77FD\u87CB\u90D7\u550F\u7699\u96B0\u6A28\u6D60\u5FFE\u8725\u6A84\u90C4\u7FD5\u960B\u9CC3\u823E\u5C63\u8478\u8785\u54AD\u7C9E\u89CB\u6B37\u50D6\u91AF\u9F37\u88FC\u7A78\u9969\u8204\u798A\u8BF6\u83E5\u84F0",
  yi: "\u4E00\u4EE5\u5DF2\u610F\u8BAE\u4E49\u76CA\u4EBF\u6613\u533B\u827A\u98DF\u4F9D\u79FB\u8863\u5F02\u4F0A\u4EEA\u5B9C\u5C04\u9057\u7591\u6BC5\u8C0A\u4EA6\u75AB\u5F79\u5FC6\u6291\u5C3E\u4E59\u8BD1\u7FFC\u86C7\u6EA2\u6905\u6C82\u6CC4\u9038\u8681\u5937\u9091\u6021\u7ECE\u5F5D\u88D4\u59E8\u71A0\u8D3B\u77E3\u5C79\u9890\u501A\u8BE3\u80F0\u5955\u7FCC\u7599\u5F08\u8F76\u86FE\u9A7F\u58F9\u7317\u81C6\u5F0B\u94F1\u65D6\u6F2A\u8FE4\u4F5A\u7FCA\u8BD2\u603F\u75CD\u61FF\u9974\u5CC4\u63D6\u7719\u9552\u4EE1\u9EDF\u8084\u54BF\u7FF3\u6339\u7F22\u5453\u5208\u54A6\u5DB7\u7FBF\u9487\u6BAA\u8351\u858F\u8734\u9571\u566B\u7654\u82E1\u6092\u55CC\u7617\u8864\u4F7E\u57F8\u572F\u8223\u914F\u5293",
  an: "\u5B89\u6848\u6309\u5CB8\u6697\u978D\u6C28\u4FFA\u80FA\u94F5\u8C19\u5EB5\u9EEF\u9E4C\u6849\u57EF\u72B4\u63DE\u5382\u5E7F",
  han: "\u5382\u6C49\u97E9\u542B\u65F1\u5BD2\u6C57\u6DB5\u51FD\u558A\u61BE\u7F55\u710A\u7FF0\u90AF\u64BC\u701A\u61A8\u634D\u9163\u608D\u9F3E\u9097\u9894\u86B6\u6657\u83E1\u65F0\u9878\u72B4\u7113\u6496",
  ang: "\u6602\u4EF0\u76CE\u80AE",
  ao: "\u5965\u6FB3\u50B2\u71AC\u51F9\u9CCC\u6556\u9068\u93D6\u8884\u5773\u7FF1\u55F7\u62D7\u61CA\u5C99\u87AF\u9A9C\u7352\u93CA\u8279\u5AAA\u5ED2\u8071",
  wa: "\u74E6\u6316\u5A03\u6D3C\u889C\u86D9\u51F9\u54C7\u4F64\u5A32\u5459\u817D",
  yu: "\u4E8E\u4E0E\u80B2\u4F59\u9884\u57DF\u4E88\u9047\u5965\u8BED\u8A89\u7389\u9C7C\u96E8\u6E14\u88D5\u6108\u5A31\u6B32\u5401\u8206\u5B87\u7FBD\u903E\u8C6B\u90C1\u5BD3\u543E\u72F1\u55BB\u5FA1\u6D74\u6109\u79B9\u4FDE\u90AA\u6986\u611A\u6E1D\u5C09\u6DE4\u865E\u5C7F\u5CEA\u7CA5\u9A6D\u745C\u79BA\u6BD3\u94B0\u9685\u828B\u71A8\u7600\u8FC2\u715C\u6631\u6C69\u65BC\u81FE\u76C2\u807F\u7AFD\u8438\u59AA\u8174\u5704\u8C15\u89CE\u63C4\u9F89\u8C00\u4FE3\u9980\u5EBE\u59A4\u7610\u9B3B\u6B24\u9E6C\u9608\u5D5B\u96E9\u9E46\u5709\u872E\u4F1B\u7EA1\u7AAC\u7AB3\u996B\u84E3\u72F3\u8080\u8201\u8753\u71E0",
  niu: "\u725B\u7EBD\u626D\u94AE\u62D7\u599E\u5FF8\u72C3",
  o: "\u54E6\u5662\u5594",
  ba: "\u628A\u516B\u5DF4\u62D4\u4F2F\u5427\u575D\u7238\u9738\u7F62\u82AD\u8DCB\u6252\u53ED\u9776\u75A4\u7B06\u8019\u9C85\u7C91\u5C9C\u705E\u94AF\u634C\u83DD\u9B43\u8307",
  pa: "\u6015\u5E15\u722C\u6252\u8DB4\u7436\u556A\u8469\u8019\u6777\u94AF\u7B62",
  pi: "\u88AB\u6279\u526F\u5426\u76AE\u574F\u8F9F\u5564\u5339\u62AB\u75B2\u7F62\u50FB\u6BD7\u576F\u813E\u8B6C\u5288\u5AB2\u5C41\u7435\u90B3\u88E8\u75DE\u7656\u9642\u4E15\u6787\u567C\u9739\u5421\u7EB0\u7812\u94CD\u6DE0\u90EB\u57E4\u6FDE\u7765\u8298\u868D\u572E\u9F19\u7F74\u8731\u758B\u8C94\u4EF3\u5E80\u64D7\u7513\u9674",
  bi: "\u6BD4\u5FC5\u5E01\u7B14\u6BD5\u79D8\u907F\u95ED\u4F5B\u8F9F\u58C1\u5F0A\u5F7C\u903C\u78A7\u9F3B\u81C2\u853D\u62C2\u6CCC\u74A7\u5E87\u75F9\u6BD9\u5F3C\u5315\u9119\u965B\u88E8\u8D32\u655D\u84D6\u5421\u7BE6\u7EB0\u4FFE\u94CB\u6BD6\u7B5A\u8378\u859C\u5A62\u54D4\u8DF8\u6FDE\u79D5\u835C\u610E\u7765\u59A3\u8298\u7B85\u9AC0\u7540\u6ED7\u72F4\u8406\u5B16\u895E\u822D",
  bai: "\u767E\u767D\u8D25\u6446\u4F2F\u62DC\u67CF\u4F70\u63B0\u5457\u64D8\u636D\u7A17",
  bo: "\u6CE2\u535A\u64AD\u52C3\u62E8\u8584\u4F5B\u4F2F\u73BB\u640F\u67CF\u6CCA\u8236\u5265\u6E24\u535C\u9A73\u7C3F\u8116\u818A\u7C38\u83E0\u7934\u7B94\u94C2\u4EB3\u94B5\u5E1B\u64D8\u997D\u8DDB\u94B9\u8DB5\u6A97\u5575\u9E41\u64D7\u8E23",
  bei: "\u5317\u88AB\u5907\u500D\u80CC\u676F\u52C3\u8D1D\u8F88\u60B2\u7891\u81C2\u5351\u6096\u60EB\u84D3\u9642\u94A1\u72C8\u5457\u7119\u789A\u8919\u5EB3\u97B4\u5B5B\u9E4E\u90B6\u943E",
  ban: "\u529E\u7248\u534A\u73ED\u822C\u677F\u9881\u4F34\u642C\u6591\u626E\u62CC\u6273\u74E3\u5742\u962A\u7ECA\u94A3\u7622\u8228\u764D",
  pan: "\u5224\u76D8\u756A\u6F58\u6500\u76FC\u62DA\u7554\u80D6\u53DB\u62CC\u8E52\u78D0\u723F\u87E0\u6CEE\u88A2\u897B\u4E2C",
  bin: "\u4EFD\u5BBE\u9891\u6EE8\u658C\u5F6C\u6FD2\u6BA1\u7F24\u9B13\u69DF\u6448\u8191\u73A2\u9554\u8C73\u9ACC\u50A7",
  bang: "\u5E2E\u90A6\u5F6D\u65C1\u699C\u68D2\u8180\u9551\u7ED1\u508D\u78C5\u868C\u8C24\u6886\u6D5C\u84A1",
  pang: "\u65C1\u5E9E\u4E53\u78C5\u8783\u5F77\u6EC2\u9004\u802A",
  beng: "\u6CF5\u5D29\u868C\u8E66\u8FF8\u7EF7\u752D\u5623\u750F\u580B",
  bao: "\u62A5\u4FDD\u5305\u5B9D\u66B4\u80DE\u8584\u7206\u70AE\u9971\u62B1\u5821\u5265\u9C8D\u66DD\u8446\u7011\u8C79\u5228\u8912\u96F9\u5B62\u82DE\u7172\u8913\u8DB5\u9E28\u9F85\u52F9",
  bu: "\u4E0D\u90E8\u6B65\u5E03\u8865\u6355\u5821\u57D4\u535C\u57E0\u7C3F\u54FA\u6016\u949A\u535F\u74FF\u900B\u6661\u91AD\u94B8",
  pu: "\u666E\u66B4\u94FA\u6D66\u6734\u5821\u8461\u8C31\u57D4\u6251\u4EC6\u84B2\u66DD\u7011\u6EA5\u8386\u5703\u749E\u6FEE\u83E9\u8E7C\u530D\u5657\u6C06\u6535\u9568\u6534\u9564",
  mian: "\u9762\u68C9\u514D\u7EF5\u7F05\u52C9\u7720\u5195\u5A29\u817C\u6E11\u6E4E\u6C94\u9EFE\u5B80\u7704",
  po: "\u7834\u7E41\u5761\u8FEB\u9887\u6734\u6CCA\u5A46\u6CFC\u9B44\u7C95\u9131\u73C0\u9642\u53F5\u7B38\u6CFA\u76A4\u948B\u94B7",
  fan: "\u53CD\u8303\u72AF\u7E41\u996D\u6CDB\u7FFB\u51E1\u8FD4\u756A\u8D29\u70E6\u62DA\u5E06\u6A0A\u85E9\u77FE\u68B5\u8543\u9492\u5E61\u7548\u8629\u8E6F\u71D4",
  fu: "\u5E9C\u670D\u526F\u8D1F\u5BCC\u590D\u798F\u592B\u5987\u5E45\u4ED8\u6276\u7236\u7B26\u9644\u8150\u8D74\u4F5B\u6D6E\u8986\u8F85\u5085\u4F0F\u629A\u8D4B\u8F90\u8179\u5F17\u80A4\u961C\u88B1\u7F1A\u752B\u6C1F\u65A7\u5B5A\u6577\u4FEF\u62C2\u4FD8\u5490\u8151\u5B75\u8299\u6DAA\u91DC\u812F\u832F\u99A5\u5B93\u7EC2\u8BA3\u544B\u7F58\u9EB8\u8760\u5310\u82BE\u8709\u8DD7\u51EB\u6ECF\u876E\u9A78\u7ECB\u86A8\u7829\u6874\u8D59\u83D4\u5452\u8DBA\u82FB\u62CA\u961D\u9C8B\u602B\u7A03\u90DB\u83A9\u5E5E\u7953\u8274\u9EFB\u9EFC\u9CC6",
  ben: "\u672C\u4F53\u5954\u82EF\u7B28\u592F\u8D32\u951B\u755A\u574C",
  feng: "\u98CE\u4E30\u5C01\u5CF0\u5949\u51E4\u950B\u51AF\u9022\u7F1D\u8702\u67AB\u75AF\u8BBD\u70FD\u4FF8\u6CA3\u9146\u781C\u8451\u552A",
  bian: "\u53D8\u4FBF\u8FB9\u7F16\u904D\u8FA9\u97AD\u8FA8\u8D2C\u533E\u6241\u535E\u6C74\u8FAB\u782D\u82C4\u8759\u9CCA\u5F01\u7A86\u7B3E\u7178\u890A\u78A5\u5FED\u7F0F",
  pian: "\u4FBF\u7247\u7BC7\u504F\u9A97\u7FE9\u6241\u9A88\u80FC\u8E41\u8C1D\u728F\u7F0F",
  zhen: "\u9547\u771F\u9488\u5733\u632F\u9707\u73CD\u9635\u8BCA\u586B\u4FA6\u81FB\u8D1E\u6795\u6862\u8D48\u796F\u5E27\u7504\u659F\u7F1C\u7BB4\u75B9\u7827\u699B\u9E29\u8F78\u7A39\u6EB1\u84C1\u80D7\u6939\u6715\u755B\u6D48",
  biao: "\u8868\u6807\u5F6A\u9556\u88F1\u98DA\u8198\u98D9\u9573\u5A4A\u9AA0\u98D1\u6753\u9ADF\u9CD4\u706C\u762D",
  piao: "\u7968\u6734\u6F02\u98D8\u5AD6\u74E2\u527D\u7F25\u6B8D\u779F\u9AA0\u560C\u83A9\u87B5",
  huo: "\u548C\u6D3B\u6216\u8D27\u83B7\u706B\u4F19\u60D1\u970D\u7978\u8C41\u56AF\u85FF\u952A\u8816\u94AC\u8020\u956C\u5925\u706C\u5290\u6509",
  bie: "\u522B\u9CD6\u618B\u762A\u8E69",
  min: "\u6C11\u654F\u95FD\u95F5\u76BF\u6CEF\u5CB7\u60AF\u73C9\u62BF\u9EFE\u7F17\u739F\u610D\u82E0\u9CD8",
  fen: "\u5206\u4EFD\u7EB7\u594B\u7C89\u6C1B\u82AC\u6124\u7CAA\u575F\u6C7E\u711A\u915A\u5429\u5FFF\u68FC\u73A2\u9F22\u7035\u507E\u9CBC",
  bing: "\u5E76\u75C5\u5175\u51B0\u5C4F\u997C\u70B3\u79C9\u4E19\u6452\u67C4\u69DF\u7980\u678B\u90B4\u51AB",
  geng: "\u66F4\u8015\u9888\u5E9A\u803F\u6897\u57C2\u7FB9\u54FD\u8D53\u7EE0\u9CA0",
  fang: "\u65B9\u653E\u623F\u9632\u8BBF\u7EBA\u82B3\u4EFF\u574A\u59A8\u80AA\u90A1\u822B\u5F77\u678B\u9C82\u531A\u94AB",
  xian: "\u73B0\u5148\u53BF\u89C1\u7EBF\u9650\u663E\u9669\u732E\u9C9C\u6D17\u5BAA\u7EA4\u9677\u95F2\u8D24\u4ED9\u8854\u6380\u54B8\u5ACC\u63BA\u7FA1\u5F26\u817A\u75EB\u5A34\u8237\u9985\u9170\u94E3\u51BC\u6D8E\u66B9\u7C7C\u9528\u82CB\u86AC\u8DF9\u5C98\u85D3\u71F9\u9E47\u6C19\u83B6\u9730\u8DE3\u7303\u5F61\u7946\u7B45",
  fou: "\u4E0D\u5426\u7F36",
  ca: "\u62C6\u64E6\u5693\u7924",
  cha: "\u67E5\u5BDF\u5DEE\u8336\u63D2\u53C9\u5239\u832C\u6942\u5C94\u8BE7\u78B4\u5693\u55B3\u59F9\u6748\u6C4A\u8869\u643D\u69CE\u9572\u82F4\u6AAB\u9987\u9538\u7339",
  cai: "\u624D\u91C7\u8D22\u6750\u83DC\u5F69\u88C1\u8521\u731C\u8E29\u776C",
  can: "\u53C2\u6B8B\u9910\u707F\u60E8\u8695\u63BA\u74A8\u60ED\u7CB2\u5B71\u9A96\u9EEA",
  shen: "\u4FE1\u6DF1\u53C2\u8EAB\u795E\u4EC0\u5BA1\u7533\u751A\u6C88\u4F38\u614E\u6E17\u80BE\u7EC5\u8398\u547B\u5A76\u5A20\u7837\u8703\u54C2\u6939\u845A\u5432\u7CC1\u6E16\u8BDC\u8C02\u77E7\u80C2",
  cen: "\u53C2\u5C91\u6D94",
  san: "\u4E09\u53C2\u6563\u4F1E\u53C1\u7CC1\u9993\u6BF5",
  cang: "\u85CF\u4ED3\u82CD\u6CA7\u8231\u81E7\u4F27",
  zang: "\u85CF\u810F\u846C\u8D43\u81E7\u5958\u9A75",
  chen: "\u79F0\u9648\u6C88\u6C89\u6668\u741B\u81E3\u5C18\u8FB0\u886C\u8D81\u5FF1\u90F4\u5BB8\u8C0C\u789C\u55D4\u62BB\u6987\u4F27\u8C36\u9F80\u809C",
  cao: "\u8349\u64CD\u66F9\u69FD\u7CD9\u5608\u6F15\u87AC\u825A\u5C6E",
  ce: "\u7B56\u6D4B\u518C\u4FA7\u5395\u6805\u607B",
  ze: "\u8D23\u5219\u6CFD\u62E9\u4FA7\u548B\u5567\u4EC4\u7BA6\u8D5C\u7B2E\u8234\u6603\u8FEE\u5E3B",
  zhai: "\u503A\u62E9\u9F50\u5B85\u5BE8\u4FA7\u6458\u7A84\u658B\u796D\u7FDF\u7826\u7635\u54DC",
  dao: "\u5230\u9053\u5BFC\u5C9B\u5012\u5200\u76D7\u7A3B\u8E48\u60BC\u6363\u53E8\u7977\u7118\u6C18\u7E9B\u5202\u5E31\u5FC9",
  ceng: "\u5C42\u66FE\u8E6D\u564C",
  zha: "\u67E5\u624E\u70B8\u8BC8\u95F8\u6E23\u548B\u4E4D\u69A8\u6942\u672D\u6805\u7728\u54A4\u67DE\u55B3\u558B\u94E1\u86B1\u5412\u600D\u781F\u63F8\u75C4\u54F3\u9F44",
  chai: "\u5DEE\u62C6\u67F4\u9497\u8C7A\u4FAA\u867F\u7625",
  ci: "\u6B21\u6B64\u5DEE\u8BCD\u8F9E\u523A\u74F7\u78C1\u5179\u6148\u8328\u8D50\u7960\u4F3A\u96CC\u75B5\u9E5A\u7CCD\u5472\u7CA2",
  zi: "\u8D44\u81EA\u5B50\u5B57\u9F50\u54A8\u6ECB\u4ED4\u59FF\u7D2B\u5179\u5B5C\u6DC4\u7C7D\u6893\u9CBB\u6E0D\u59CA\u5431\u79ED\u6063\u753E\u5B73\u8A3E\u6ED3\u9531\u8F8E\u8D91\u9F87\u8D40\u7726\u7F01\u5472\u7B2B\u8C18\u5D6B\u9AED\u8308\u7CA2\u89DC\u8014",
  cuo: "\u63AA\u9519\u78CB\u632B\u6413\u64AE\u8E49\u9509\u539D\u5D6F\u75E4\u77EC\u7625\u811E\u9E7E",
  chan: "\u4EA7\u5355\u9610\u5D2D\u7F20\u63BA\u7985\u98A4\u94F2\u8749\u6400\u6F7A\u87FE\u998B\u5FCF\u5A75\u5B71\u89C7\u5EDB\u8C04\u8C17\u6FB6\u9AA3\u7FBC\u8E94\u8487\u5181",
  shan: "\u5C71\u5355\u5584\u9655\u95EA\u886B\u64C5\u6C55\u6247\u63BA\u73CA\u7985\u5220\u81B3\u7F2E\u8D61\u912F\u6805\u717D\u59D7\u8DDA\u9CDD\u5B17\u6F78\u8BAA\u8222\u82EB\u759D\u63B8\u81BB\u9490\u5261\u87EE\u829F\u57CF\u5F61\u9A9F",
  zhan: "\u5C55\u6218\u5360\u7AD9\u5D2D\u7C98\u6E5B\u6CBE\u77BB\u98A4\u8A79\u65A9\u76CF\u8F97\u7EFD\u6BE1\u6808\u8638\u65C3\u8C35\u640C",
  xin: "\u65B0\u5FC3\u4FE1\u8F9B\u6B23\u85AA\u99A8\u946B\u82AF\u950C\u5FFB\u8398\u6615\u8845\u6B46\u56DF\u5FC4\u9561",
  lian: "\u8054\u8FDE\u7EC3\u5EC9\u70BC\u8138\u83B2\u604B\u94FE\u5E18\u601C\u6D9F\u655B\u740F\u9570\u6FC2\u695D\u9CA2\u6B93\u6F4B\u88E2\u88E3\u81C1\u5941\u83B6\u880A\u8539",
  chang: "\u573A\u957F\u5382\u5E38\u507F\u660C\u5531\u7545\u5021\u5C1D\u80A0\u655E\u5018\u7316\u5A3C\u6DCC\u88F3\u5F9C\u6636\u6005\u5AE6\u83D6\u9CB3\u960A\u4F25\u82CC\u6C05\u60DD\u9B2F",
  zhang: "\u957F\u5F20\u7AE0\u969C\u6DA8\u638C\u5E10\u80C0\u5F70\u4E08\u4ED7\u6F33\u6A1F\u8D26\u6756\u748B\u5D82\u4EC9\u7634\u87D1\u7350\u5E5B\u9123\u5ADC",
  chao: "\u8D85\u671D\u6F6E\u7092\u949E\u6284\u5DE2\u5435\u527F\u7EF0\u5632\u6641\u712F\u8016\u600A",
  zhao: "\u7740\u7167\u62DB\u627E\u53EC\u671D\u8D75\u5146\u662D\u8087\u7F69\u948A\u6CBC\u5632\u722A\u8BCF\u6FEF\u5541\u68F9\u7B0A",
  zhou: "\u8C03\u5DDE\u5468\u6D32\u821F\u9AA4\u8F74\u663C\u5B99\u7CA5\u76B1\u8098\u5492\u5E1A\u80C4\u7EC9\u7EA3\u59AF\u5541\u8BCC\u7E47\u78A1\u7C40\u914E\u836E",
  che: "\u8F66\u5F7B\u64A4\u5C3A\u626F\u6F88\u63A3\u577C\u7817\u5C6E",
  ju: "\u8F66\u5C40\u636E\u5177\u4E3E\u4E14\u5C45\u5267\u5DE8\u805A\u6E20\u8DDD\u53E5\u62D2\u4FF1\u67DC\u83CA\u62D8\u70AC\u6854\u60E7\u77E9\u97A0\u9A79\u952F\u8E1E\u5480\u77BF\u67B8\u63AC\u6CAE\u8392\u6A58\u98D3\u75BD\u949C\u8D84\u8E3D\u907D\u741A\u9F83\u6910\u82E3\u88FE\u6998\u72D9\u5028\u6989\u82F4\u8BB5\u96CE\u9514\u7AAD\u97AB\u728B\u5C66\u91B5",
  cheng: "\u6210\u7A0B\u57CE\u627F\u79F0\u76DB\u62A2\u4E58\u8BDA\u5448\u51C0\u60E9\u6491\u6F84\u79E4\u6A59\u9A8B\u901E\u77A0\u4E1E\u665F\u94DB\u57D5\u584D\u86CF\u67FD\u94D6\u9172\u88CE\u67A8",
  rong: "\u5BB9\u8363\u878D\u7ED2\u6EB6\u84C9\u7194\u620E\u6995\u8338\u5197\u5D58\u809C\u72E8\u877E",
  sheng: "\u751F\u58F0\u5347\u80DC\u76DB\u4E58\u5723\u5269\u7272\u7538\u7701\u7EF3\u7B19\u7525\u5D4A\u665F\u6E11\u771A",
  deng: "\u7B49\u767B\u9093\u706F\u6F84\u51F3\u77AA\u8E6C\u5654\u78F4\u5D9D\u956B\u7C26\u6225",
  zhi: "\u5236\u4E4B\u6CBB\u8D28\u804C\u53EA\u5FD7\u81F3\u6307\u7EC7\u652F\u503C\u77E5\u8BC6\u76F4\u81F4\u6267\u7F6E\u6B62\u690D\u7EB8\u62D3\u667A\u6B96\u79E9\u65E8\u5740\u6EDE\u6C0F\u679D\u829D\u8102\u5E1C\u6C41\u80A2\u631A\u7A1A\u916F\u63B7\u5CD9\u7099\u6809\u4F84\u82B7\u7A92\u54AB\u5431\u8DBE\u75D4\u8718\u90C5\u684E\u96C9\u7949\u90E6\u965F\u75E3\u86ED\u5E19\u67B3\u8E2F\u5FB5\u80DD\u6800\u8D3D\u7957\u8C78\u9E37\u646D\u8F75\u536E\u8F7E\u5F58\u89EF\u7D77\u8DD6\u57F4\u5902\u9EF9\u5FEE\u9A98\u81A3\u8E2C",
  zheng: "\u653F\u6B63\u8BC1\u4E89\u6574\u5F81\u90D1\u4E01\u75C7\u6323\u84B8\u7741\u94EE\u7B5D\u62EF\u5CE5\u6014\u8BE4\u72F0\u5FB5\u94B2",
  tang: "\u5802\u5510\u7CD6\u6C64\u5858\u8EBA\u8D9F\u5018\u68E0\u70EB\u6DCC\u819B\u642A\u9557\u50A5\u87B3\u6E8F\u5E11\u7FB0\u6A18\u91A3\u8797\u8025\u94F4\u746D",
  chi: "\u6301\u5403\u6C60\u8FDF\u8D64\u9A70\u5C3A\u65A5\u9F7F\u7FC5\u5319\u75F4\u803B\u70BD\u4F88\u5F1B\u53F1\u557B\u577B\u7719\u55E4\u5880\u54E7\u830C\u8C49\u6555\u7B1E\u996C\u8E1F\u86A9\u67E2\u5AB8\u9B51\u7BEA\u892B\u5F73\u9E31\u87AD\u761B\u7735\u50BA",
  shi: "\u662F\u65F6\u5B9E\u4E8B\u5E02\u5341\u4F7F\u4E16\u65BD\u5F0F\u52BF\u89C6\u8BC6\u5E08\u53F2\u793A\u77F3\u98DF\u59CB\u58EB\u5931\u9002\u8BD5\u4EC0\u6CFD\u5BA4\u4F3C\u8BD7\u9970\u6B96\u91CA\u9A76\u6C0F\u7855\u901D\u6E7F\u8680\u72EE\u8A93\u62FE\u5C38\u5319\u4ED5\u67FF\u77E2\u5CD9\u4F8D\u566C\u55DC\u6805\u62ED\u5618\u5C4E\u6043\u8F7C\u8671\u8006\u8210\u83B3\u94C8\u8C25\u70BB\u8C55\u9CA5\u9963\u87AB\u917E\u7B6E\u57D8\u5F11\u793B\u84CD\u9CBA\u8D33",
  qi: "\u4F01\u5176\u8D77\u671F\u6C14\u4E03\u5668\u6C7D\u5947\u9F50\u542F\u65D7\u68CB\u59BB\u5F03\u63ED\u679D\u6B67\u6B3A\u9A91\u5951\u8FC4\u4E9F\u6F06\u621A\u5C82\u7A3D\u5C90\u7426\u6816\u7F09\u742A\u6CE3\u4E5E\u780C\u7941\u5D0E\u7EEE\u797A\u7948\u51C4\u6DC7\u675E\u8110\u9E92\u573B\u61A9\u82AA\u4F0E\u4FDF\u7566\u8006\u847A\u6C8F\u840B\u9A90\u9CCD\u7DA6\u8BAB\u8572\u5C7A\u9880\u4E93\u789B\u67D2\u5550\u6C54\u7DAE\u8401\u5601\u86F4\u69ED\u6B39\u8291\u6864\u4E0C\u871E",
  chuai: "\u63E3\u8E39\u555C\u640B\u81AA",
  tuo: "\u6258\u8131\u62D3\u62D6\u59A5\u9A7C\u9640\u6CB1\u9E35\u9A6E\u553E\u692D\u5768\u4F57\u7823\u8DCE\u5EB9\u67C1\u6A50\u4E47\u94CA\u6CB2\u9161\u9F0D\u7BA8\u67DD",
  duo: "\u591A\u5EA6\u593A\u6735\u8EB2\u94CE\u968B\u5484\u5815\u8235\u579B\u60F0\u54C6\u8E31\u8DFA\u6387\u5241\u67C1\u7F0D\u6CB2\u88F0\u54DA\u96B3",
  xue: "\u5B66\u8840\u96EA\u524A\u859B\u7A74\u9774\u8C11\u5671\u9CD5\u8E05\u6CF6\u5F50",
  chong: "\u91CD\u79CD\u5145\u51B2\u6D8C\u5D07\u866B\u5BA0\u5FE1\u61A7\u8202\u833A\u94F3\u825F",
  chou: "\u7B79\u62BD\u7EF8\u916C\u6101\u4E11\u81ED\u4EC7\u7574\u7A20\u7785\u8E0C\u60C6\u4FE6\u7633\u96E0\u5E31",
  qiu: "\u6C42\u7403\u79CB\u4E18\u90B1\u4EC7\u914B\u88D8\u9F9F\u56DA\u9052\u9CC5\u866C\u86AF\u6CC5\u6978\u6E6B\u72B0\u9011\u5DEF\u827D\u4FC5\u8764\u8D47\u9F3D\u7CD7",
  xiu: "\u4FEE\u79C0\u4F11\u5BBF\u8896\u7EE3\u81ED\u673D\u9508\u7F9E\u55C5\u5CAB\u6EB4\u5EA5\u9990\u54BB\u9AF9\u9E3A\u8C85",
  chu: "\u51FA\u5904\u7840\u521D\u52A9\u9664\u50A8\u755C\u89E6\u695A\u53A8\u96CF\u77D7\u6A71\u9504\u6EC1\u8E87\u6035\u7ECC\u6410\u520D\u870D\u9EDC\u6775\u8E70\u4E8D\u6A17\u61B7\u696E",
  tuan: "\u56E2\u63E3\u6E4D\u7583\u629F\u5F56",
  zhui: "\u8FFD\u5760\u7F00\u63E3\u690E\u9525\u8D58\u60F4\u96B9\u9A93\u7F12",
  chuan: "\u4F20\u5DDD\u8239\u7A7F\u4E32\u5598\u693D\u821B\u948F\u9044\u6C1A\u5DDB\u8221",
  zhuan: "\u4E13\u8F6C\u4F20\u8D5A\u7816\u64B0\u7BC6\u9994\u556D\u989B",
  yuan: "\u5143\u5458\u9662\u539F\u6E90\u8FDC\u613F\u56ED\u63F4\u5706\u7F18\u8881\u6028\u6E0A\u82D1\u5B9B\u51A4\u5A9B\u733F\u57A3\u6C85\u586C\u57B8\u9E33\u8F95\u9E22\u7457\u571C\u7230\u82AB\u9F0B\u6A7C\u8788\u7722\u7BA2\u63BE",
  cuan: "\u7A9C\u6512\u7BE1\u8E7F\u64BA\u7228\u6C46\u9569",
  chuang: "\u521B\u5E8A\u7A97\u95EF\u5E62\u75AE\u6006",
  zhuang: "\u88C5\u72B6\u5E84\u58EE\u649E\u5986\u5E62\u6869\u5958\u50EE\u6206",
  chui: "\u5439\u5782\u9524\u708A\u690E\u9672\u69CC\u6376\u68F0",
  chun: "\u6625\u7EAF\u9187\u6DF3\u5507\u693F\u8822\u9E51\u6710\u83BC\u80AB\u877D",
  zhun: "\u51C6\u5C6F\u6DF3\u8C06\u80AB\u7A80",
  cu: "\u4FC3\u8D8B\u8DA3\u7C97\u7C07\u918B\u5352\u8E74\u731D\u8E59\u851F\u6B82\u5F82",
  dun: "\u5428\u987F\u76FE\u6566\u8E72\u58A9\u56E4\u6C8C\u949D\u7096\u76F9\u9041\u8DB8\u7818\u7905",
  qu: "\u533A\u53BB\u53D6\u66F2\u8D8B\u6E20\u8DA3\u9A71\u5C48\u8EAF\u8862\u5A36\u795B\u77BF\u5C96\u9F8B\u89D1\u6710\u86D0\u766F\u86C6\u82E3\u9612\u8BCE\u52AC\u8556\u8627\u6C0D\u9EE2\u883C\u74A9\u9EB4\u9E32\u78F2",
  xu: "\u9700\u8BB8\u7EED\u987B\u5E8F\u5F90\u4F11\u84C4\u755C\u865A\u5401\u7EEA\u53D9\u65ED\u90AA\u6064\u589F\u6829\u7D6E\u5729\u5A7F\u620C\u80E5\u5618\u6D52\u7166\u9157\u8BE9\u6710\u76F1\u84FF\u6E86\u6D2B\u987C\u52D6\u7CC8\u7809\u9191",
  chuo: "\u8F8D\u7EF0\u6233\u6DD6\u555C\u9F8A\u8E14\u8FB6",
  zu: "\u7EC4\u65CF\u8DB3\u7956\u79DF\u963B\u5352\u4FCE\u8BC5\u955E\u83F9",
  ji: "\u6D4E\u673A\u5176\u6280\u57FA\u8BB0\u8BA1\u7CFB\u671F\u9645\u53CA\u96C6\u7EA7\u51E0\u7ED9\u79EF\u6781\u5DF1\u7EAA\u5373\u7EE7\u51FB\u65E2\u6FC0\u7EE9\u6025\u5947\u5409\u5B63\u9F50\u75BE\u8FF9\u9E21\u5242\u8F91\u7C4D\u5BC4\u6324\u573E\u5180\u4E9F\u5BC2\u66A8\u810A\u8DFB\u808C\u7A3D\u5FCC\u9965\u796D\u7F09\u68D8\u77F6\u6C72\u7578\u59EC\u85C9\u7620\u9AA5\u7F81\u5993\u8BA5\u7A37\u84DF\u60B8\u5AC9\u5C8C\u53FD\u4F0E\u9CAB\u8BD8\u696B\u8360\u621F\u7B95\u9701\u5D47\u89CA\u9E82\u757F\u7391\u7B08\u7284\u82A8\u5527\u5C50\u9AFB\u6222\u4F76\u5048\u7B04\u8DFD\u84BA\u4E69\u54AD\u8D4D\u5D74\u866E\u638E\u9F51\u6B9B\u9C9A\u525E\u6D0E\u4E0C\u58BC\u857A\u5F50\u82B0\u54DC",
  cong: "\u4ECE\u4E1B\u5306\u806A\u8471\u56F1\u742E\u6DD9\u679E\u9AA2\u82C1\u7481",
  zong: "\u603B\u4ECE\u7EFC\u5B97\u7EB5\u8E2A\u68D5\u7CBD\u9B03\u506C\u679E\u8159",
  cou: "\u51D1\u8F8F\u8160\u6971",
  cui: "\u8870\u50AC\u5D14\u8106\u7FE0\u8403\u7CB9\u6467\u7480\u7601\u60B4\u6DEC\u5550\u96B9\u6BF3\u69B1",
  wei: "\u4E3A\u4F4D\u59D4\u672A\u7EF4\u536B\u56F4\u8FDD\u5A01\u4F1F\u5371\u5473\u5FAE\u552F\u8C13\u4F2A\u6170\u5C3E\u9B4F\u97E6\u80C3\u754F\u5E37\u5582\u5DCD\u840E\u851A\u7EAC\u6F4D\u5C09\u6E2D\u60DF\u8587\u82C7\u709C\u5729\u5A13\u8BFF\u73AE\u5D34\u6845\u504E\u9036\u502D\u7325\u56D7\u8473\u9697\u75FF\u732C\u6DA0\u5D6C\u97EA\u7168\u8249\u96B9\u5E0F\u95F1\u6D27\u6CA9\u9688\u9C94\u8ECE",
  cun: "\u6751\u5B58\u5BF8\u5FD6\u76B4",
  zuo: "\u4F5C\u505A\u5EA7\u5DE6\u5750\u6628\u4F50\u7422\u64AE\u795A\u67DE\u5511\u562C\u9162\u600D\u7B2E\u963C\u80D9",
  zuan: "\u94BB\u7E82\u6525\u7F35\u8E9C",
  da: "\u5927\u8FBE\u6253\u7B54\u642D\u6C93\u7629\u60EE\u55D2\u54D2\u8037\u9791\u977C\u8921\u7B2A\u601B\u59B2",
  dai: "\u5927\u4EE3\u5E26\u5F85\u8D37\u6BD2\u6234\u888B\u6B79\u5446\u96B6\u902E\u5CB1\u50A3\u68E3\u6020\u6B86\u9EDB\u7519\u57ED\u8BD2\u7ED0\u73B3\u5454\u8FE8",
  tai: "\u5927\u53F0\u592A\u6001\u6CF0\u62AC\u80CE\u6C70\u949B\u82D4\u85B9\u80BD\u8DC6\u90B0\u9C90\u915E\u9A80\u70B1",
  ta: "\u4ED6\u5B83\u5979\u62D3\u5854\u8E0F\u584C\u69BB\u6C93\u6F2F\u736D\u55D2\u631E\u8E4B\u8DBF\u9062\u94CA\u9CCE\u6EBB\u95FC",
  dan: "\u4F46\u5355\u77F3\u62C5\u4E39\u80C6\u65E6\u5F39\u86CB\u6DE1\u8BDE\u6C2E\u90F8\u803D\u6B9A\u60EE\u510B\u7708\u75B8\u6FB9\u63B8\u81BB\u5556\u7BAA\u8043\u840F\u7605\u8D55",
  lu: "\u8DEF\u516D\u9646\u5F55\u7EFF\u9732\u9C81\u5362\u7089\u9E7F\u7984\u8D42\u82A6\u5E90\u788C\u9E93\u9885\u6CF8\u5364\u6F5E\u9E6D\u8F98\u864F\u7490\u6F09\u565C\u622E\u9C88\u63B3\u6A79\u8F73\u902F\u6E0C\u84FC\u64B8\u9E2C\u680C\u6C07\u80EA\u9565\u7C0F\u823B\u8F82\u5786",
  tan: "\u8C08\u63A2\u5766\u644A\u5F39\u70AD\u575B\u6EE9\u8D2A\u53F9\u8C2D\u6F6D\u78B3\u6BEF\u762B\u6A80\u75F0\u8892\u574D\u8983\u5FD0\u6619\u90EF\u6FB9\u94BD\u952C",
  ren: "\u4EBA\u4EFB\u8BA4\u4EC1\u5FCD\u97E7\u5203\u7EAB\u996A\u598A\u834F\u7A14\u58EC\u4EDE\u8F6B\u4EBB\u887D",
  jie: "\u5BB6\u7ED3\u89E3\u4EF7\u754C\u63A5\u8282\u5979\u5C4A\u4ECB\u9636\u8857\u501F\u6770\u6D01\u622A\u59D0\u63ED\u6377\u52AB\u6212\u7686\u7AED\u6854\u8BEB\u6977\u79F8\u776B\u85C9\u62EE\u82A5\u8BD8\u78A3\u55DF\u9889\u86A7\u5B51\u5A55\u7596\u6840\u8BA6\u75A5\u5048\u7FAF\u88B7\u54DC\u5588\u5369\u9C92\u9AB1",
  yan: "\u7814\u4E25\u9A8C\u6F14\u8A00\u773C\u70DF\u6CBF\u5EF6\u76D0\u708E\u71D5\u5CA9\u5BB4\u8273\u989C\u6BB7\u5F66\u63A9\u6DF9\u960E\u884D\u94C5\u96C1\u54BD\u538C\u7130\u5830\u781A\u5501\u7109\u664F\u6A90\u8712\u5944\u4FE8\u814C\u598D\u8C1A\u5156\u7B75\u7131\u5043\u95EB\u5AE3\u9122\u6E6E\u8D5D\u80ED\u7430\u6EDF\u9609\u9B47\u917D\u90FE\u6079\u5D26\u82AB\u5261\u9F39\u83F8\u990D\u57CF\u8C33\u8BA0\u53A3\u7F68",
  dang: "\u5F53\u515A\u6863\u8361\u6321\u5B95\u7800\u94DB\u88C6\u51FC\u83EA\u8C20",
  tao: "\u5957\u8BA8\u8DF3\u9676\u6D9B\u9003\u6843\u8404\u6DD8\u638F\u6ED4\u97EC\u53E8\u6D2E\u5555\u7EE6\u9955\u9F17",
  tiao: "\u6761\u8C03\u6311\u8DF3\u8FE2\u773A\u82D5\u7A95\u7B24\u4F7B\u5541\u7C9C\u9AEB\u94EB\u7967\u9F86\u8729\u9CA6",
  te: "\u7279\u5FD1\u5FD2\u94FD\u615D",
  de: "\u7684\u5730\u5F97\u5FB7\u5E95\u951D",
  dei: "\u5F97",
  di: "\u7684\u5730\u7B2C\u63D0\u4F4E\u5E95\u62B5\u5F1F\u8FEA\u9012\u5E1D\u654C\u5824\u8482\u7F14\u6EF4\u6DA4\u7FDF\u5A23\u7B1B\u68E3\u837B\u8C1B\u72C4\u90B8\u5600\u7825\u577B\u8BCB\u5AE1\u955D\u78B2\u9AB6\u6C10\u67E2\u7C74\u7F9D\u7747\u89CC",
  ti: "\u4F53\u63D0\u9898\u5F1F\u66FF\u68AF\u8E22\u60D5\u5254\u8E44\u68E3\u557C\u5C49\u5243\u6D95\u9511\u501C\u608C\u9016\u568F\u8351\u918D\u7EE8\u9E48\u7F07\u88FC",
  tui: "\u63A8\u9000\u5F1F\u817F\u892A\u9893\u8715\u5FD2\u717A",
  you: "\u6709\u7531\u53C8\u4F18\u6E38\u6CB9\u53CB\u53F3\u90AE\u5C24\u5FE7\u5E7C\u72B9\u8BF1\u60A0\u5E7D\u4F51\u91C9\u67DA\u94C0\u9C7F\u56FF\u9149\u6538\u9EDD\u83A0\u7337\u8763\u75A3\u5466\u86B4\u83B8\u839C\u94D5\u5BA5\u7E47\u5363\u7256\u9F2C\u5C22\u86B0\u4F91",
  dian: "\u7535\u70B9\u5E97\u5178\u5960\u7538\u7898\u6DC0\u6BBF\u57AB\u98A0\u6EC7\u766B\u5DC5\u60E6\u6382\u765C\u73B7\u4F43\u8E2E\u975B\u94BF\u7C1F\u576B\u963D",
  tian: "\u5929\u7530\u6DFB\u586B\u751C\u7538\u606C\u8146\u4F43\u8214\u94BF\u9617\u5FDD\u6B84\u754B\u681D\u63AD",
  zhu: "\u4E3B\u672F\u4F4F\u6CE8\u52A9\u5C5E\u9010\u5B81\u8457\u7B51\u9A7B\u6731\u73E0\u795D\u732A\u8BF8\u67F1\u7AF9\u94F8\u682A\u77A9\u5631\u8D2E\u716E\u70DB\u82CE\u891A\u86DB\u62C4\u94E2\u6D19\u7AFA\u86C0\u6E1A\u4F2B\u677C\u4F8F\u6F8D\u8BDB\u8331\u7BB8\u70B7\u8E85\u7FE5\u6F74\u90BE\u69E0\u8233\u6A65\u4E36\u7603\u9E88\u75B0",
  nian: "\u5E74\u5FF5\u917F\u8F97\u78BE\u5EFF\u637B\u64B5\u62C8\u852B\u9CB6\u57DD\u9C87\u8F87\u9ECF",
  diao: "\u8C03\u6389\u96D5\u540A\u9493\u5201\u8C82\u51CB\u7889\u9CB7\u53FC\u94EB\u94DE",
  yao: "\u8981\u4E48\u7EA6\u836F\u9080\u6447\u8000\u8170\u9065\u59DA\u7A91\u7476\u54AC\u5C27\u94A5\u8C23\u80B4\u592D\u4FA5\u5406\u759F\u5996\u5E7A\u6773\u8200\u7A95\u7A88\u66DC\u9E5E\u723B\u7E47\u5FAD\u8F7A\u94EB\u9CD0\u5D3E\u73E7",
  die: "\u8DCC\u53E0\u8776\u8FED\u789F\u7239\u8C0D\u7252\u800B\u4F5A\u558B\u581E\u74DE\u9CBD\u57A4\u63F2\u8E40",
  she: "\u8BBE\u793E\u6444\u6D89\u5C04\u6298\u820D\u86C7\u62FE\u820C\u5962\u6151\u8D66\u8D4A\u4F58\u9E9D\u6B59\u7572\u538D\u731E\u63F2\u6EE0",
  ye: "\u4E1A\u4E5F\u591C\u53F6\u5C04\u91CE\u6DB2\u51B6\u559D\u9875\u7237\u8036\u90AA\u54BD\u6930\u70E8\u6396\u62FD\u66F3\u6654\u8C12\u814B\u564E\u63F6\u9765\u90BA\u94D8\u63F2",
  xie: "\u4E9B\u89E3\u534F\u5199\u8840\u53F6\u8C22\u68B0\u978B\u80C1\u659C\u643A\u61C8\u5951\u5378\u8C10\u6CC4\u87F9\u90AA\u6B47\u6CFB\u5C51\u631F\u71EE\u69AD\u874E\u64B7\u5055\u4EB5\u6954\u9889\u7F2C\u9082\u9C91\u7023\u52F0\u698D\u85A4\u7EC1\u6E2B\u5EE8\u736C\u8E9E",
  zhe: "\u8FD9\u8005\u7740\u8457\u6D59\u6298\u54F2\u8517\u906E\u8F99\u8F84\u67D8\u9517\u8936\u8707\u86F0\u9E67\u8C2A\u8D6D\u647A\u4E47\u78D4\u87AB",
  ding: "\u5B9A\u8BA2\u9876\u4E01\u9F0E\u76EF\u9489\u952D\u53EE\u4EC3\u94E4\u753A\u914A\u5576\u7887\u815A\u7594\u738E\u8035",
  diu: "\u4E22\u94E5",
  ting: "\u542C\u5EAD\u505C\u5385\u5EF7\u633A\u4EAD\u8247\u5A77\u6C40\u94E4\u70C3\u9706\u753A\u8713\u8476\u6883\u839B",
  dong: "\u52A8\u4E1C\u8463\u51AC\u6D1E\u61C2\u51BB\u680B\u4F97\u549A\u5CD2\u6C21\u606B\u80F4\u7850\u578C\u9E2B\u5CBD\u80E8",
  tong: "\u540C\u901A\u7EDF\u7AE5\u75DB\u94DC\u6876\u6850\u7B52\u5F64\u4F97\u4F5F\u6F7C\u6345\u916E\u783C\u77B3\u6078\u5CD2\u4EDD\u55F5\u50EE\u578C\u833C",
  zhong: "\u4E2D\u91CD\u79CD\u4F17\u7EC8\u949F\u5FE0\u4EF2\u8877\u80BF\u8E35\u51A2\u76C5\u86A3\u5FEA\u953A\u822F\u87BD\u5902",
  dou: "\u90FD\u6597\u8BFB\u8C46\u6296\u515C\u9661\u9017\u7AA6\u6E0E\u86AA\u75D8\u8538\u94AD\u7BFC",
  du: "\u5EA6\u90FD\u72EC\u7763\u8BFB\u6BD2\u6E21\u675C\u5835\u8D4C\u7779\u809A\u9540\u6E0E\u7B03\u7AFA\u561F\u728A\u5992\u724D\u8839\u691F\u9EE9\u828F\u9AD1",
  duan: "\u65AD\u6BB5\u77ED\u7AEF\u953B\u7F0E\u7145\u6934\u7C16",
  dui: "\u5BF9\u961F\u8FFD\u6566\u5151\u5806\u7893\u9566\u603C\u619D",
  rui: "\u745E\u5151\u9510\u777F\u82AE\u854A\u8564\u868B\u6798",
  yue: "\u6708\u8BF4\u7EA6\u8D8A\u4E50\u8DC3\u5151\u9605\u5CB3\u7CA4\u60A6\u66F0\u94A5\u680E\u94BA\u6A3E\u7039\u9FA0\u54D5\u5216",
  tun: "\u541E\u5C6F\u56E4\u892A\u8C5A\u81C0\u9968\u66BE\u6C3D",
  hui: "\u4F1A\u56DE\u6325\u6C47\u60E0\u8F89\u6062\u5FBD\u7ED8\u6BC1\u6167\u7070\u8D3F\u5349\u6094\u79FD\u6E83\u835F\u6656\u5F57\u8BB3\u8BF2\u73F2\u5815\u8BD9\u8559\u6666\u7762\u9EBE\u70E9\u8334\u5599\u6867\u86D4\u6D04\u6D4D\u867A\u605A\u87EA\u54B4\u96B3\u7F0B\u54D5",
  wu: "\u52A1\u7269\u65E0\u4E94\u6B66\u5348\u5434\u821E\u4F0D\u6C61\u4E4C\u8BEF\u4EA1\u6076\u5C4B\u6664\u609F\u543E\u96FE\u829C\u68A7\u52FF\u5DEB\u4FAE\u575E\u6BCB\u8BEC\u545C\u94A8\u90AC\u6342\u9E5C\u5140\u5A7A\u59A9\u65BC\u620A\u9E49\u6D6F\u8708\u5514\u9A9B\u4EF5\u7110\u82B4\u92C8\u5E91\u9F2F\u727E\u6003\u572C\u5FE4\u75E6\u8FD5\u674C\u5BE4\u9622",
  ya: "\u4E9A\u538B\u96C5\u7259\u62BC\u9E2D\u5440\u8F67\u6DAF\u5D16\u90AA\u82BD\u54D1\u8BB6\u9E26\u5A05\u8859\u4E2B\u869C\u78A3\u57AD\u4F22\u6C29\u6860\u740A\u63E0\u5416\u775A\u75D6\u758B\u8FD3\u5C88\u7811",
  he: "\u548C\u5408\u6CB3\u4F55\u6838\u76D6\u8D3A\u559D\u8D6B\u8377\u76D2\u9E64\u5413\u5475\u82DB\u79BE\u83CF\u58D1\u8910\u6DB8\u9602\u9616\u52BE\u8BC3\u988C\u55EC\u8C89\u66F7\u7FEE\u7EA5\u76CD",
  wo: "\u6211\u63E1\u7A9D\u6C83\u5367\u631D\u6DA1\u65A1\u6E25\u5E44\u8717\u5594\u502D\u83B4\u9F8C\u809F\u786A",
  en: "\u6069\u6441\u84BD",
  n: "\u55EF\u5514",
  er: "\u800C\u4E8C\u5C14\u513F\u8033\u8FE9\u9975\u6D31\u8D30\u94D2\u73E5\u4F74\u9E38\u9C95",
  fa: "\u53D1\u6CD5\u7F5A\u4E4F\u4F10\u9600\u7B4F\u781D\u57A1\u73D0",
  quan: "\u5168\u6743\u5238\u6CC9\u5708\u62F3\u529D\u72AC\u94E8\u75CA\u8BE0\u8343\u919B\u8737\u98A7\u7EFB\u72AD\u7B4C\u9B08\u609B\u8F81\u754E",
  fei: "\u8D39\u975E\u98DE\u80A5\u5E9F\u83F2\u80BA\u5561\u6CB8\u532A\u6590\u871A\u5983\u8BFD\u6249\u7FE1\u970F\u5420\u7EEF\u8153\u75F1\u82BE\u6DDD\u60B1\u72D2\u69A7\u7829\u9CB1\u7BDA\u9544",
  pei: "\u914D\u57F9\u574F\u8D54\u4F69\u966A\u6C9B\u88F4\u80DA\u5983\u9708\u6DE0\u65C6\u5E14\u5478\u9185\u8F94\u952B",
  ping: "\u5E73\u8BC4\u51ED\u74F6\u51AF\u5C4F\u840D\u82F9\u4E52\u576A\u67B0\u5A09\u4FDC\u9C86",
  fo: "\u4F5B",
  hu: "\u548C\u62A4\u8BB8\u6237\u6838\u6E56\u4E92\u4E4E\u547C\u80E1\u620F\u5FFD\u864E\u6CAA\u7CCA\u58F6\u846B\u72D0\u8774\u5F27\u745A\u6D52\u9E44\u7425\u6248\u552C\u6EF9\u60DA\u795C\u56EB\u659B\u7B0F\u82B4\u9190\u7322\u6019\u553F\u623D\u69F2\u89F3\u7173\u9E55\u51B1\u74E0\u864D\u5CB5\u9E71\u70C0\u8F77",
  ga: "\u5939\u5496\u560E\u5C2C\u5676\u65EE\u4F3D\u5C15\u9486\u5C1C",
  ge: "\u4E2A\u5408\u5404\u9769\u683C\u6B4C\u54E5\u76D6\u9694\u5272\u9601\u6208\u845B\u9E3D\u6401\u80F3\u8238\u7599\u94EC\u9ABC\u86E4\u54AF\u572A\u9549\u988C\u4EE1\u784C\u55DD\u9B32\u8188\u7EA5\u88BC\u643F\u5865\u54FF\u867C",
  ha: "\u54C8\u86E4\u94EA",
  xia: "\u4E0B\u590F\u5CE1\u53A6\u8F96\u971E\u5939\u867E\u72ED\u5413\u4FA0\u6687\u9050\u778E\u5323\u7455\u552C\u5477\u9EE0\u7856\u7F45\u72CE\u7615\u67D9",
  gai: "\u6539\u8BE5\u76D6\u6982\u6E89\u9499\u4E10\u82A5\u8D45\u5793\u9654\u6224",
  hai: "\u6D77\u8FD8\u5BB3\u5B69\u4EA5\u54B3\u9AB8\u9A87\u6C26\u55E8\u80F2\u91A2",
  gan: "\u5E72\u611F\u8D76\u6562\u7518\u809D\u6746\u8D63\u4E7E\u67D1\u5C34\u7AFF\u79C6\u6A44\u77F8\u6DE6\u82F7\u64C0\u9150\u7EC0\u6CD4\u5769\u65F0\u75B3\u6F89",
  gang: "\u6E2F\u94A2\u521A\u5C97\u7EB2\u5188\u6760\u7F38\u625B\u809B\u7F61\u6206\u7B7B",
  jiang: "\u5C06\u5F3A\u6C5F\u6E2F\u5956\u8BB2\u964D\u7586\u848B\u59DC\u6D46\u5320\u9171\u50F5\u6868\u7EDB\u7F30\u729F\u8C47\u7913\u6D1A\u8333\u7CE8\u8029",
  hang: "\u884C\u822A\u676D\u5DF7\u592F\u542D\u6841\u6C86\u7ED7\u9883",
  gong: "\u5DE5\u516C\u5171\u4F9B\u529F\u7EA2\u8D21\u653B\u5BAB\u5DE9\u9F9A\u606D\u62F1\u8EAC\u5F13\u6C5E\u86A3\u73D9\u89E5\u80B1\u5EFE",
  hong: "\u7EA2\u5B8F\u6D2A\u8F70\u8679\u9E3F\u5F18\u54C4\u70D8\u6CD3\u8A07\u857B\u95F3\u8BA7\u836D\u9EC9\u85A8",
  guang: "\u5E7F\u5149\u901B\u6F62\u72B7\u80F1\u54A3\u6844",
  qiong: "\u7A77\u743C\u7A79\u909B\u8315\u7B47\u8DEB\u86E9\u928E",
  gao: "\u9AD8\u544A\u641E\u7A3F\u818F\u7CD5\u9550\u768B\u7F94\u9506\u6772\u90DC\u777E\u8BF0\u85C1\u7BD9\u7F1F\u69C1\u69D4",
  hao: "\u597D\u53F7\u6BEB\u8C6A\u8017\u6D69\u90DD\u7693\u660A\u768B\u84BF\u58D5\u704F\u568E\u6FE0\u869D\u8C89\u98A2\u55E5\u8585\u5686",
  li: "\u7406\u529B\u5229\u7ACB\u91CC\u674E\u5386\u4F8B\u79BB\u52B1\u793C\u4E3D\u9ECE\u7483\u5389\u5398\u7C92\u8389\u68A8\u96B6\u6817\u8354\u6CA5\u7281\u6F13\u54E9\u72F8\u85DC\u7F79\u7BF1\u9CA4\u783A\u540F\u6FA7\u4FD0\u9A8A\u6EA7\u783E\u8385\u9502\u7B20\u8821\u86CE\u75E2\u96F3\u4FEA\u5088\u91B4\u680E\u90E6\u4FDA\u67A5\u55B1\u9026\u5A0C\u9E42\u623E\u782C\u5533\u575C\u75A0\u870A\u9EE7\u7301\u9B32\u7C9D\u84E0\u5456\u8DDE\u75AC\u7F21\u9CA1\u9CE2\u5AE0\u8A48\u609D\u82C8\u7BE5\u8F79",
  jia: "\u5BB6\u52A0\u4EF7\u5047\u4F73\u67B6\u7532\u5609\u8D3E\u9A7E\u5AC1\u5939\u7A3C\u94BE\u631F\u62EE\u8FE6\u4F3D\u988A\u6D43\u67B7\u621B\u835A\u75C2\u9889\u9553\u7B33\u73C8\u5CAC\u80DB\u8888\u90CF\u846D\u88B7\u7615\u94D7\u8DCF\u86F1\u605D\u54FF",
  luo: "\u843D\u7F57\u7EDC\u6D1B\u903B\u87BA\u9523\u9A86\u841D\u88F8\u6F2F\u70D9\u645E\u9AA1\u54AF\u7BA9\u73DE\u634B\u8366\u784C\u96D2\u6924\u9559\u8DDE\u7630\u6CFA\u8136\u7321\u502E\u8803",
  ke: "\u53EF\u79D1\u514B\u5BA2\u523B\u8BFE\u9897\u6E34\u58F3\u67EF\u68F5\u5475\u5777\u606A\u82DB\u54B3\u78D5\u73C2\u7A1E\u778C\u6E98\u8F72\u7AA0\u55D1\u75B4\u874C\u5CA2\u94EA\u988F\u9AC1\u86B5\u7F02\u6C2A\u9A92\u94B6\u951E",
  qia: "\u5361\u6070\u6D3D\u6390\u9AC2\u88B7\u54AD\u845C",
  gei: "\u7ED9",
  gen: "\u6839\u8DDF\u4E98\u826E\u54CF\u831B",
  hen: "\u5F88\u72E0\u6068\u75D5\u54CF",
  gou: "\u6784\u8D2D\u591F\u53E5\u6C9F\u72D7\u94A9\u62D8\u52FE\u82DF\u57A2\u67B8\u7BDD\u4F5D\u5ABE\u8BDF\u5CA3\u5F40\u7F11\u7B31\u97B2\u89CF\u9058",
  kou: "\u53E3\u6263\u5BC7\u53E9\u62A0\u4F5D\u853B\u82A4\u770D\u7B58",
  gu: "\u80A1\u53E4\u987E\u6545\u56FA\u9F13\u9AA8\u4F30\u8C37\u8D3E\u59D1\u5B64\u96C7\u8F9C\u83C7\u6CBD\u5495\u5471\u9522\u94B4\u7B8D\u6C69\u688F\u75FC\u5D2E\u8F71\u9E2A\u726F\u86CA\u8BC2\u6BC2\u9E58\u83F0\u7F5F\u560F\u81CC\u89DA\u77BD\u86C4\u9164\u727F\u9CB4",
  pai: "\u724C\u6392\u6D3E\u62CD\u8FEB\u5F98\u6E43\u4FF3\u54CC\u848E",
  gua: "\u62EC\u6302\u74DC\u522E\u5BE1\u5366\u5471\u8902\u5250\u80CD\u8BD6\u9E39\u681D\u5459",
  tou: "\u6295\u5934\u900F\u5077\u6109\u9AB0\u4EA0",
  guai: "\u602A\u62D0\u4E56",
  kuai: "\u4F1A\u5FEB\u5757\u7B77\u810D\u84AF\u4FA9\u6D4D\u90D0\u8489\u72EF\u54D9",
  guan: "\u5173\u7BA1\u89C2\u9986\u5B98\u8D2F\u51A0\u60EF\u704C\u7F50\u839E\u7EB6\u68FA\u65A1\u77DC\u500C\u9E73\u9CCF\u76E5\u63BC\u6DAB",
  wan: "\u4E07\u5B8C\u665A\u6E7E\u73A9\u7897\u987D\u633D\u5F2F\u8513\u4E38\u839E\u7696\u5B9B\u5A49\u8155\u873F\u60CB\u70F7\u742C\u7579\u8C4C\u525C\u7EA8\u7EFE\u8118\u83C0\u8284\u7BA2",
  ne: "\u5462\u54EA\u5450\u8BB7\u7592",
  gui: "\u89C4\u8D35\u5F52\u8F68\u6842\u67DC\u572D\u9B3C\u7845\u7470\u8DEA\u9F9F\u532E\u95FA\u8BE1\u7678\u9CDC\u6867\u7688\u9C91\u523D\u6677\u5080\u772D\u59AB\u7085\u5E8B\u7C0B\u523F\u5B84\u5326",
  jun: "\u519B\u5747\u4FCA\u541B\u5CFB\u83CC\u7AE3\u94A7\u9A8F\u9F9F\u6D5A\u96BD\u90E1\u7B60\u76B2\u9E87\u6343",
  jiong: "\u7A98\u70AF\u8FE5\u7085\u5182\u6243",
  jue: "\u51B3\u7EDD\u89D2\u89C9\u6398\u5D1B\u8BC0\u7357\u6289\u7235\u56BC\u5014\u53A5\u8568\u652B\u73CF\u77CD\u8E76\u8C32\u9562\u9CDC\u5671\u6877\u5658\u6485\u6A5B\u5B53\u89D6\u5282\u721D",
  gun: "\u6EDA\u68CD\u8F8A\u886E\u78D9\u9CA7\u7EF2\u4E28",
  hun: "\u5A5A\u6DF7\u9B42\u6D51\u660F\u68CD\u73F2\u8364\u9984\u8BE8\u6EB7\u960D",
  guo: "\u56FD\u8FC7\u679C\u90ED\u9505\u88F9\u5E3C\u6DA1\u6901\u56D7\u8748\u8662\u8052\u57DA\u63B4\u7313\u5D1E\u873E\u5459\u9998",
  hei: "\u9ED1\u563F\u55E8",
  kan: "\u770B\u520A\u52D8\u582A\u574E\u780D\u4F83\u5D4C\u69DB\u77B0\u961A\u9F9B\u6221\u51F5\u83B0",
  heng: "\u8861\u6A2A\u6052\u4EA8\u54FC\u73E9\u6841\u8605",
  mo: "\u4E07\u6CA1\u4E48\u6A21\u672B\u5192\u83AB\u6469\u58A8\u9ED8\u78E8\u6478\u6F20\u8109\u819C\u9B54\u6CAB\u964C\u62B9\u5BDE\u8611\u6479\u84E6\u998D\u8309\u563F\u8C1F\u79E3\u87C6\u8C89\u5AEB\u9546\u6B81\u8031\u5B37\u9EBD\u763C\u8C8A\u8C98",
  peng: "\u9E4F\u670B\u5F6D\u81A8\u84EC\u78B0\u82F9\u68DA\u6367\u4EA8\u70F9\u7BF7\u6F8E\u62A8\u787C\u6026\u7830\u562D\u87DB\u580B",
  hou: "\u540E\u5019\u539A\u4FAF\u7334\u5589\u543C\u9005\u7BCC\u7CC7\u9ABA\u5F8C\u9C8E\u760A\u5820",
  hua: "\u5316\u534E\u5212\u8BDD\u82B1\u753B\u6ED1\u54D7\u8C41\u9A85\u6866\u733E\u94E7\u7809",
  huai: "\u6000\u574F\u6DEE\u5F8A\u69D0\u8E1D",
  huan: "\u8FD8\u73AF\u6362\u6B22\u60A3\u7F13\u5524\u7115\u5E7B\u75EA\u6853\u5BF0\u6DA3\u5BA6\u57B8\u6D39\u6D63\u8C62\u5942\u90C7\u571C\u737E\u9CA9\u9B1F\u8411\u902D\u6F36\u953E\u7F33\u64D0",
  xun: "\u8BAF\u8BAD\u8FC5\u5B59\u5BFB\u8BE2\u5FAA\u65EC\u5DE1\u6C5B\u52CB\u900A\u718F\u5F87\u6D5A\u6B89\u9A6F\u9C9F\u85B0\u8340\u6D54\u6D35\u5CCB\u57D9\u5DFD\u90C7\u91BA\u6042\u8368\u7AA8\u8548\u66DB\u736F",
  huang: "\u9EC4\u8352\u714C\u7687\u51F0\u614C\u6643\u6F62\u8C0E\u60F6\u7C27\u749C\u604D\u5E4C\u6E5F\u8757\u78FA\u968D\u5FA8\u9051\u8093\u7BC1\u9CC7\u87E5\u7640",
  nai: "\u80FD\u4E43\u5976\u8010\u5948\u9F10\u8418\u6C16\u67F0\u4F74\u827F",
  luan: "\u4E71\u5375\u6EE6\u5CE6\u9E3E\u683E\u92AE\u631B\u5B6A\u8114\u5A08",
  qie: "\u5207\u4E14\u5951\u7A83\u8304\u780C\u9532\u602F\u4F3D\u60EC\u59BE\u8D84\u6308\u90C4\u7BA7\u614A",
  jian: "\u5EFA\u95F4\u4EF6\u89C1\u575A\u68C0\u5065\u76D1\u51CF\u7B80\u8270\u8DF5\u517C\u9274\u952E\u6E10\u67EC\u5251\u5C16\u80A9\u8230\u8350\u7BAD\u6D45\u526A\u4FED\u78B1\u8327\u5978\u6B7C\u62E3\u6361\u714E\u8D31\u6E85\u69DB\u6DA7\u5811\u7B3A\u8C0F\u996F\u950F\u7F04\u7751\u8B07\u8E47\u8171\u83C5\u7FE6\u622C\u6BFD\u7B15\u728D\u7877\u97AF\u726E\u67A7\u6E54\u9CA3\u56DD\u88E5\u8E3A\u641B\u7F23\u9E63\u84B9\u8C2B\u50ED\u620B\u8DBC\u6957",
  nan: "\u5357\u96BE\u7537\u6960\u5583\u56E1\u8D67\u8169\u56DD\u877B",
  qian: "\u524D\u5343\u94B1\u7B7E\u6F5C\u8FC1\u6B20\u7EA4\u7275\u6D45\u9063\u8C26\u4E7E\u94C5\u6B49\u9ED4\u8C34\u5D4C\u5029\u94B3\u831C\u8654\u5811\u948E\u9A9E\u9621\u63AE\u94A4\u6266\u828A\u728D\u8368\u4EDF\u82A1\u60AD\u7F31\u4F65\u6106\u8930\u51F5\u80B7\u5C8D\u6434\u7B9D\u614A\u6920",
  qiang: "\u5F3A\u62A2\u7586\u5899\u67AA\u8154\u9535\u545B\u7F8C\u8537\u8941\u7F9F\u8DC4\u6A2F\u6215\u5AF1\u6217\u709D\u956A\u9516\u8723",
  xiang: "\u5411\u9879\u76F8\u60F3\u4E61\u8C61\u54CD\u9999\u964D\u50CF\u4EAB\u7BB1\u7F8A\u7965\u6E58\u8BE6\u6A61\u5DF7\u7FD4\u8944\u53A2\u9576\u98E8\u9977\u7F03\u9AA7\u8297\u5EA0\u9C9E\u8459\u87D3",
  jiao: "\u6559\u4EA4\u8F83\u6821\u89D2\u89C9\u53EB\u811A\u7F34\u80F6\u8F7F\u90CA\u7126\u9A84\u6D47\u6912\u7901\u4F7C\u8549\u5A07\u77EB\u6405\u7EDE\u9175\u527F\u56BC\u997A\u7A96\u8DE4\u86DF\u4FA5\u72E1\u59E3\u768E\u832D\u5CE4\u94F0\u91AE\u9C9B\u6E6B\u5FBC\u9E6A\u50EC\u564D\u827D\u6322\u656B",
  zhuo: "\u7740\u8457\u7F34\u684C\u5353\u6349\u7422\u707C\u6D4A\u914C\u62D9\u8301\u6DBF\u956F\u6DD6\u5544\u6FEF\u712F\u502C\u64E2\u65AB\u68F9\u8BFC\u6D5E\u799A",
  qiao: "\u6865\u4E54\u4FA8\u5DE7\u6084\u6572\u4FCF\u58F3\u96C0\u77A7\u7FD8\u7A8D\u5CED\u9539\u64AC\u835E\u8DF7\u6A35\u6194\u9798\u6A47\u5CE4\u8BEE\u8C2F\u6100\u9792\u7857\u5281\u7F32",
  xiao: "\u5C0F\u6548\u9500\u6D88\u6821\u6653\u7B11\u8096\u524A\u5B5D\u8427\u4FCF\u6F47\u785D\u5BB5\u5578\u56A3\u9704\u6DC6\u54EE\u7B71\u900D\u59E3\u7BAB\u9A81\u67AD\u54D3\u7EE1\u86F8\u5D24\u67B5\u9B48",
  si: "\u53F8\u56DB\u601D\u65AF\u98DF\u79C1\u6B7B\u4F3C\u4E1D\u9972\u5BFA\u8086\u6495\u6CD7\u4F3A\u55E3\u7940\u53AE\u9A77\u5636\u9536\u4FDF\u5DF3\u86F3\u549D\u801C\u7B25\u7E9F\u7CF8\u9E36\u7F0C\u6F8C\u59D2\u6C5C\u53B6\u5155",
  kai: "\u5F00\u51EF\u6168\u5C82\u6977\u607A\u63E9\u9534\u94E0\u5FFE\u57B2\u5240\u950E\u8488",
  jin: "\u8FDB\u91D1\u4ECA\u8FD1\u4EC5\u7D27\u5C3D\u6D25\u65A4\u7981\u9526\u52B2\u664B\u8C28\u7B4B\u5DFE\u6D78\u895F\u9773\u747E\u70EC\u7F19\u9485\u77DC\u89D0\u5807\u9991\u8369\u5664\u5ED1\u5997\u69FF\u8D46\u887F\u537A",
  qin: "\u4EB2\u52E4\u4FB5\u79E6\u94A6\u7434\u79BD\u82B9\u6C81\u5BDD\u64D2\u8983\u5659\u77DC\u55EA\u63FF\u6EB1\u82A9\u887E\u5ED1\u9513\u5423\u6A8E\u8793",
  jing: "\u7ECF\u4EAC\u7CBE\u5883\u7ADE\u666F\u8B66\u7ADF\u4E95\u60CA\u5F84\u9759\u52B2\u656C\u51C0\u955C\u775B\u6676\u9888\u8346\u5162\u9756\u6CFE\u61AC\u9CB8\u830E\u8148\u83C1\u80EB\u9631\u65CC\u7CB3\u9753\u75C9\u7B90\u5106\u8FF3\u5A67\u80BC\u522D\u5F2A\u734D",
  ying: "\u5E94\u8425\u5F71\u82F1\u666F\u8FCE\u6620\u786C\u76C8\u8D62\u9896\u5A74\u9E70\u8367\u83B9\u6A31\u745B\u8747\u8426\u83BA\u988D\u81BA\u7F28\u701B\u6979\u7F42\u8365\u8424\u9E66\u6EE2\u84E5\u90E2\u8314\u5624\u748E\u5B34\u763F\u5AB5\u6484\u6F46",
  jiu: "\u5C31\u7A76\u4E5D\u9152\u4E45\u6551\u65E7\u7EA0\u8205\u7078\u759A\u63EA\u548E\u97ED\u7396\u81FC\u67E9\u8D73\u9E20\u9E6B\u53A9\u557E\u9604\u6855\u50E6\u9B0F",
  zui: "\u6700\u7F6A\u5634\u9189\u5480\u855E\u89DC",
  juan: "\u5377\u6350\u5708\u7737\u5A1F\u5026\u7EE2\u96BD\u954C\u6D93\u9E43\u9104\u8832\u72F7\u9529\u684A",
  suan: "\u7B97\u9178\u849C\u72FB",
  yun: "\u5458\u8FD0\u4E91\u5141\u5B55\u8574\u97F5\u915D\u8018\u6655\u5300\u82B8\u9668\u7EAD\u90E7\u7B60\u607D\u97EB\u90D3\u6C32\u6B92\u6120\u6600\u83C0\u72C1",
  qun: "\u7FA4\u88D9\u9021\u9E87",
  ka: "\u5361\u5580\u5496\u5494\u54AF\u4F67\u80E9",
  kang: "\u5EB7\u6297\u625B\u6177\u7095\u4EA2\u7CE0\u4F09\u94AA\u95F6",
  keng: "\u5751\u94FF\u542D",
  kao: "\u8003\u9760\u70E4\u62F7\u94D0\u6832\u5C3B\u7292",
  ken: "\u80AF\u57A6\u6073\u5543\u9F88\u88C9",
  yin: "\u56E0\u5F15\u94F6\u5370\u97F3\u996E\u9634\u9690\u59FB\u6BB7\u6DEB\u5C39\u836B\u541F\u763E\u5BC5\u8335\u573B\u57A0\u911E\u6E6E\u8693\u6C24\u80E4\u9F88\u7AA8\u5591\u94DF\u6D07\u72FA\u5924\u5EF4\u5432\u972A\u831A\u5819",
  kong: "\u7A7A\u63A7\u5B54\u6050\u5025\u5D06\u7B9C",
  ku: "\u82E6\u5E93\u54ED\u9177\u88E4\u67AF\u7A9F\u630E\u9AB7\u5800\u7ED4\u5233\u55BE",
  kua: "\u8DE8\u5938\u57AE\u630E\u80EF\u4F89",
  kui: "\u4E8F\u594E\u6127\u9B41\u9988\u6E83\u532E\u8475\u7AA5\u76D4\u9035\u777D\u9997\u8069\u559F\u5914\u7BD1\u5CBF\u55B9\u63C6\u9697\u5080\u668C\u8DEC\u8489\u6126\u609D\u8770",
  kuan: "\u6B3E\u5BBD\u9ACB",
  kuang: "\u51B5\u77FF\u6846\u72C2\u65F7\u7736\u5321\u7B50\u909D\u5739\u54D0\u8D36\u593C\u8BF3\u8BD3\u7EA9",
  que: "\u786E\u5374\u7F3A\u96C0\u9E4A\u9619\u7638\u69B7\u7094\u9615\u60AB",
  kun: "\u56F0\u6606\u5764\u6346\u7428\u951F\u9CB2\u918C\u9AE1\u6083\u9603",
  kuo: "\u6269\u62EC\u9614\u5ED3\u86DE",
  la: "\u62C9\u843D\u5783\u814A\u5566\u8FA3\u8721\u5587\u524C\u65EF\u782C\u908B\u760C",
  lai: "\u6765\u83B1\u8D56\u7750\u5F95\u7C41\u6D9E\u8D49\u6FD1\u765E\u5D03\u75A0\u94FC",
  lan: "\u5170\u89C8\u84DD\u7BEE\u680F\u5C9A\u70C2\u6EE5\u7F06\u63FD\u6F9C\u62E6\u61D2\u6984\u6593\u5A6A\u9611\u8934\u7F71\u5549\u8C30\u9567\u6F24",
  lin: "\u6797\u4E34\u90BB\u8D41\u7433\u78F7\u6DCB\u9E9F\u9716\u9CDE\u51DB\u62CE\u9074\u853A\u541D\u7CBC\u5D99\u8E8F\u5EEA\u6AA9\u5549\u8F9A\u81A6\u77B5\u61D4",
  lang: "\u6D6A\u6717\u90CE\u5ECA\u72FC\u7405\u6994\u8782\u9606\u9512\u83A8\u5577\u8497\u7A02",
  liang: "\u91CF\u4E24\u7CAE\u826F\u8F86\u4EAE\u6881\u51C9\u8C05\u7CB1\u667E\u9753\u8E09\u83A8\u690B\u9B49\u589A",
  lao: "\u8001\u52B3\u843D\u7EDC\u7262\u635E\u6D9D\u70D9\u59E5\u4F6C\u5D02\u5520\u916A\u6F66\u75E8\u91AA\u94D1\u94F9\u6833\u8022",
  mu: "\u76EE\u6A21\u6728\u4EA9\u5E55\u6BCD\u7267\u83AB\u7A46\u59C6\u5893\u6155\u725F\u7261\u52DF\u7766\u7F2A\u6C90\u66AE\u62C7\u59E5\u94BC\u82DC\u4EEB\u6BEA\u5776",
  le: "\u4E86\u4E50\u52D2\u808B\u53FB\u9CD3\u561E\u4EC2\u6CD0",
  lei: "\u7C7B\u7D2F\u96F7\u52D2\u6CEA\u857E\u5792\u78CA\u64C2\u956D\u808B\u7FB8\u8012\u5121\u5AD8\u7F27\u9179\u561E\u8BD4\u6A91",
  sui: "\u968F\u5C81\u867D\u788E\u5C3F\u96A7\u9042\u9AD3\u7A57\u7EE5\u968B\u9083\u7762\u795F\u6FC9\u71E7\u8C07\u772D\u837D",
  lie: "\u5217\u70C8\u52A3\u88C2\u730E\u51BD\u54A7\u8D94\u6D0C\u9B23\u57D2\u6369\u8E90",
  leng: "\u51B7\u6123\u68F1\u695E\u5844",
  ling: "\u9886\u4EE4\u53E6\u96F6\u7075\u9F84\u9675\u5CAD\u51CC\u73B2\u94C3\u83F1\u68F1\u4F36\u7F9A\u82D3\u8046\u7FCE\u6CE0\u74F4\u56F9\u7EEB\u5464\u68C2\u86C9\u9143\u9CAE\u67C3",
  lia: "\u4FE9",
  liao: "\u4E86\u6599\u7597\u8FBD\u5ED6\u804A\u5BE5\u7F2A\u50DA\u71CE\u7F2D\u6482\u64A9\u5639\u6F66\u9563\u5BEE\u84FC\u7360\u948C\u5C25\u9E69",
  liu: "\u6D41\u5218\u516D\u7559\u67F3\u7624\u786B\u6E9C\u788C\u6D4F\u69B4\u7409\u998F\u905B\u938F\u9A9D\u7EFA\u954F\u65D2\u7198\u9E68\u950D",
  lun: "\u8BBA\u8F6E\u4F26\u4ED1\u7EB6\u6CA6\u62A1\u56F5",
  lv: "\u7387\u5F8B\u65C5\u7EFF\u8651\u5C65\u5415\u94DD\u5C61\u6C2F\u7F15\u6EE4\u4FA3\u9A74\u6988\u95FE\u507B\u891B\u634B\u8182\u7A06",
  lou: "\u697C\u9732\u6F0F\u964B\u5A04\u6402\u7BD3\u55BD\u9542\u507B\u7618\u9AC5\u8027\u877C\u5D5D\u848C",
  mao: "\u8D38\u6BDB\u77DB\u5192\u8C8C\u8302\u8305\u5E3D\u732B\u9AE6\u951A\u61CB\u88A4\u7266\u536F\u94C6\u8004\u5CC1\u7441\u87CA\u8306\u8765\u65C4\u6CD6\u6634\u7780",
  long: "\u9F99\u9686\u5F04\u5784\u7B3C\u62E2\u804B\u9647\u80E7\u73D1\u7ABF\u830F\u5499\u783B\u5785\u6CF7\u680A\u7643",
  nong: "\u519C\u6D53\u5F04\u8113\u4FAC\u54DD",
  shuang: "\u53CC\u723D\u971C\u5B40\u6CF7",
  shu: "\u672F\u4E66\u6570\u5C5E\u6811\u8F93\u675F\u8FF0\u7F72\u6731\u719F\u6B8A\u852C\u8212\u758F\u9F20\u6DD1\u53D4\u6691\u67A2\u5885\u4FDE\u66D9\u6292\u7AD6\u8700\u85AF\u68B3\u620D\u6055\u5B70\u6CAD\u8D4E\u5EB6\u6F31\u587E\u500F\u6F8D\u7EBE\u59DD\u83FD\u9ECD\u8167\u79EB\u6BF9\u6BB3\u758B\u6445",
  shuai: "\u7387\u8870\u5E05\u6454\u7529\u87C0",
  lve: "\u7565\u63A0\u950A",
  ma: "\u4E48\u9A6C\u5417\u6469\u9EBB\u7801\u5988\u739B\u561B\u9A82\u62B9\u8682\u551B\u87C6\u72B8\u6769",
  me: "\u4E48\u9EBD",
  mai: "\u4E70\u5356\u9EA6\u8FC8\u8109\u57CB\u973E\u836C\u52A2",
  man: "\u6EE1\u6162\u66FC\u6F2B\u57CB\u8513\u7792\u86EE\u9CD7\u9992\u5E54\u8C29\u87A8\u71B3\u7F26\u9558\u989F\u5881\u9794",
  mi: "\u7C73\u5BC6\u79D8\u8FF7\u5F25\u871C\u8C1C\u89C5\u9761\u6CCC\u772F\u9E8B\u7315\u8C27\u54AA\u7CDC\u5B93\u6C68\u919A\u5627\u5F2D\u8112\u5196\u5E42\u7962\u7E3B\u863C\u8288\u7CF8\u6549",
  men: "\u4EEC\u95E8\u95F7\u7792\u6C76\u626A\u7116\u61D1\u9794\u9494",
  mang: "\u5FD9\u76F2\u832B\u8292\u6C13\u83BD\u87D2\u9099\u786D\u6F2D",
  meng: "\u8499\u76DF\u68A6\u731B\u5B5F\u840C\u6C13\u6726\u9530\u6AAC\u52D0\u61F5\u87D2\u8722\u867B\u9EFE\u8813\u8268\u750D\u824B\u77A2\u791E",
  miao: "\u82D7\u79D2\u5999\u63CF\u5E99\u7784\u7F2A\u6E3A\u6DFC\u85D0\u7F08\u9088\u9E4B\u676A\u7707\u55B5",
  mou: "\u67D0\u8C0B\u725F\u7F2A\u7738\u54DE\u936A\u86D1\u4F94\u53B6",
  miu: "\u7F2A\u8C2C",
  mei: "\u7F8E\u6CA1\u6BCF\u7164\u6885\u5A92\u679A\u59B9\u7709\u9B45\u9709\u6627\u5A9A\u73AB\u9176\u9541\u6E44\u5BD0\u8393\u8882\u6963\u7CDC\u5D4B\u9545\u6D7C\u7338\u9E5B",
  wen: "\u6587\u95EE\u95FB\u7A33\u6E29\u7EB9\u543B\u868A\u96EF\u7D0A\u761F\u6C76\u97EB\u520E\u74BA\u739F\u960C",
  mie: "\u706D\u8511\u7BFE\u4E5C\u54A9\u881B",
  ming: "\u660E\u540D\u547D\u9E23\u94ED\u51A5\u8317\u6E9F\u9169\u7791\u879F\u669D",
  na: "\u5185\u5357\u90A3\u7EB3\u62FF\u54EA\u5A1C\u94A0\u5450\u637A\u8872\u954E\u80AD",
  nei: "\u5185\u90A3\u54EA\u9981",
  nuo: "\u96BE\u8BFA\u632A\u5A1C\u7CEF\u61E6\u50A9\u558F\u6426\u9518",
  ruo: "\u82E5\u5F31\u504C\u7BAC",
  nang: "\u56CA\u9995\u56D4\u66E9\u652E",
  nao: "\u8111\u95F9\u607C\u6320\u7459\u6DD6\u5B6C\u57B4\u94D9\u6861\u5476\u7847\u7331\u86F2",
  ni: "\u4F60\u5C3C\u5462\u6CE5\u7591\u62DF\u9006\u502A\u59AE\u817B\u533F\u9713\u6EBA\u65CE\u6635\u576D\u94CC\u9CB5\u4F32\u6029\u7768\u730A",
  nen: "\u5AE9\u6041",
  neng: "\u80FD",
  nin: "\u60A8\u6041",
  niao: "\u9E1F\u5C3F\u6EBA\u8885\u8132\u8311\u5B32",
  nie: "\u6444\u8042\u634F\u6D85\u954D\u5B7D\u637B\u8616\u556E\u8E51\u55EB\u81EC\u954A\u989E\u4E5C\u9667",
  niang: "\u5A18\u917F",
  ning: "\u5B81\u51DD\u62E7\u6CDE\u67E0\u549B\u72DE\u4F5E\u804D\u752F",
  nu: "\u52AA\u6012\u5974\u5F29\u9A7D\u5E11\u5B65\u80EC",
  nv: "\u5973\u9495\u8844\u6067",
  ru: "\u5165\u5982\u5973\u4E73\u5112\u8FB1\u6C5D\u8339\u8925\u5B7A\u6FE1\u8815\u5685\u7F1B\u6EBD\u94F7\u6D33\u85B7\u8966\u98A5\u84D0",
  nuan: "\u6696",
  nve: "\u8650\u759F",
  re: "\u70ED\u82E5\u60F9\u558F",
  ou: "\u533A\u6B27\u5076\u6BB4\u5455\u79BA\u85D5\u8BB4\u9E25\u74EF\u6CA4\u8026\u6004",
  pao: "\u8DD1\u70AE\u6CE1\u629B\u5228\u888D\u5486\u75B1\u5E96\u72CD\u530F\u812C",
  pou: "\u5256\u638A\u88D2",
  pen: "\u55B7\u76C6\u6E53",
  pie: "\u77A5\u6487\u82E4\u6C15\u4E3F",
  pin: "\u54C1\u8D2B\u8058\u9891\u62FC\u62DA\u98A6\u59D8\u5AD4\u6980\u725D",
  se: "\u8272\u585E\u745F\u6DA9\u556C\u7A51\u94EF\u69ED",
  qing: "\u60C5\u9752\u6E05\u8BF7\u4EB2\u8F7B\u5E86\u503E\u9877\u537F\u6674\u6C22\u64CE\u6C30\u7F44\u78EC\u873B\u7B90\u9CAD\u7DAE\u82D8\u9EE5\u570A\u6AA0\u8B26",
  zan: "\u8D5E\u6682\u6512\u5811\u661D\u7C2A\u7CCC\u74D2\u933E\u8DB1\u62F6",
  shao: "\u5C11\u7ECD\u53EC\u70E7\u7A0D\u90B5\u54E8\u97F6\u634E\u52FA\u68A2\u9798\u828D\u82D5\u52AD\u8244\u7B72\u6753\u6F72",
  sao: "\u626B\u9A9A\u5AC2\u68A2\u7F2B\u6414\u7619\u81CA\u57FD\u7F32\u9CCB",
  sha: "\u6C99\u53A6\u6740\u7EB1\u7802\u5565\u838E\u5239\u6749\u50BB\u715E\u9CA8\u970E\u55C4\u75E7\u88DF\u6332\u94E9\u553C\u6B43",
  xuan: "\u53BF\u9009\u5BA3\u5238\u65CB\u60AC\u8F69\u55A7\u7384\u7EDA\u6E32\u7487\u70AB\u8431\u7663\u6F29\u7729\u6684\u714A\u94C9\u6966\u6CEB\u8C16\u75C3\u78B9\u63CE\u955F\u5107",
  ran: "\u7136\u67D3\u71C3\u5189\u82D2\u9AEF\u86BA",
  rang: "\u8BA9\u58E4\u6518\u56B7\u74E4\u7A70\u79B3",
  rao: "\u7ED5\u6270\u9976\u5A06\u6861\u835B",
  reng: "\u4ECD\u6254",
  ri: "\u65E5",
  rou: "\u8089\u67D4\u63C9\u7CC5\u97A3\u8E42",
  ruan: "\u8F6F\u962E\u670A",
  run: "\u6DA6\u95F0",
  sa: "\u8428\u6D12\u6492\u98D2\u5345\u4EE8\u810E",
  suo: "\u6240\u4E9B\u7D22\u7F29\u9501\u838E\u68AD\u7410\u55E6\u5506\u5522\u5A11\u84D1\u7FA7\u6332\u686B\u55CD\u7743",
  sai: "\u601D\u8D5B\u585E\u816E\u567B\u9CC3",
  shui: "\u8BF4\u6C34\u7A0E\u8C01\u7761\u6C35",
  sang: "\u6851\u4E27\u55D3\u6421\u98A1\u78C9",
  sen: "\u68EE",
  seng: "\u50E7",
  shai: "\u7B5B\u6652",
  shang: "\u4E0A\u5546\u5C1A\u4F24\u8D4F\u6C64\u88F3\u5892\u664C\u57A7\u89DE\u6B87\u71B5\u7EF1",
  xing: "\u884C\u7701\u661F\u8165\u7329\u60FA\u5174\u5211\u578B\u5F62\u90A2\u9967\u9192\u5E78\u674F\u6027\u59D3\u9649\u8347\u8365\u64E4\u60BB\u784E",
  shou: "\u6536\u624B\u53D7\u9996\u552E\u6388\u5B88\u5BFF\u7626\u517D\u72E9\u7EF6\u824F\u624C",
  shuo: "\u8BF4\u6570\u7855\u70C1\u6714\u94C4\u5981\u69CA\u84B4\u6420",
  su: "\u901F\u7D20\u82CF\u8BC9\u7F29\u5851\u8083\u4FD7\u5BBF\u7C9F\u6EAF\u9165\u5919\u612B\u7C0C\u7A23\u50F3\u8C21\u6D91\u850C\u55C9\u89EB",
  shua: "\u5237\u800D\u5530",
  shuan: "\u6813\u62F4\u6DAE\u95E9",
  shun: "\u987A\u77AC\u821C\u542E",
  song: "\u9001\u677E\u5B8B\u8BBC\u9882\u8038\u8BF5\u5D69\u6DDE\u6002\u609A\u5D27\u51C7\u5FEA\u7AE6\u83D8",
  sou: "\u8258\u641C\u64DE\u55FD\u55D6\u53DF\u998A\u85AE\u98D5\u55FE\u6EB2\u953C\u878B\u778D",
  sun: "\u635F\u5B59\u7B0B\u836A\u69AB\u96BC\u72F2\u98E7",
  teng: "\u817E\u75BC\u85E4\u6ED5\u8A8A",
  tie: "\u94C1\u8D34\u5E16\u992E\u841C",
  tu: "\u571F\u7A81\u56FE\u9014\u5F92\u6D82\u5410\u5C60\u5154\u79C3\u51F8\u837C\u948D\u83DF\u580D\u9174",
  wai: "\u5916\u6B6A\u5D34",
  wang: "\u738B\u671B\u5F80\u7F51\u5FD8\u4EA1\u65FA\u6C6A\u6789\u5984\u60D8\u7F54\u8F8B\u9B4D",
  weng: "\u7FC1\u55E1\u74EE\u84CA\u8579",
  zhua: "\u6293\u631D\u722A",
  yang: "\u6837\u517B\u592E\u9633\u6D0B\u626C\u6768\u7F8A\u8BE6\u6C27\u4EF0\u79E7\u75D2\u6F3E\u75A1\u6CF1\u6B83\u6059\u9E2F\u5F89\u4F6F\u600F\u7080\u70CA\u9785\u86D8",
  xiong: "\u96C4\u5144\u718A\u80F8\u51F6\u5308\u6C79\u828E",
  yo: "\u54DF\u5537",
  yong: "\u7528\u6C38\u62E5\u52C7\u6D8C\u6CF3\u5EB8\u4FD1\u8E0A\u4F63\u548F\u96CD\u752C\u955B\u81C3\u9095\u86F9\u607F\u6175\u58C5\u75C8\u9CD9\u5889\u9954\u5581",
  za: "\u6742\u624E\u54B1\u7838\u548B\u531D\u5482\u62F6",
  zai: "\u5728\u518D\u707E\u8F7D\u683D\u4ED4\u5BB0\u54C9\u5D3D\u753E",
  zao: "\u9020\u65E9\u906D\u67A3\u566A\u7076\u71E5\u7CDF\u51FF\u8E81\u85FB\u7682\u6FA1\u86A4\u5523",
  zei: "\u8D3C",
  zen: "\u600E\u8C2E",
  zeng: "\u589E\u66FE\u7EFC\u8D60\u618E\u9503\u7511\u7F7E\u7F2F",
  zhei: "\u8FD9",
  zou: "\u8D70\u90B9\u594F\u63CD\u8BF9\u9A7A\u966C\u6971\u9139\u9CB0",
  zhuai: "\u8F6C\u62FD",
  zun: "\u5C0A\u9075\u9CDF\u6A3D\u6499",
  dia: "\u55F2",
  nou: "\u8028"
};

function _(e) {
  return n.createElement("svg", Object.assign({
    xmlns: "http://www.w3.org/2000/svg",
    width: "28",
    height: "29.526",
    viewBox: "0 0 28 29.526"
  }, e), n.createElement("g", {
    id: "drag",
    transform: "translate(-1623.5 -915.5)"
  }, n.createElement("line", {
    y2: "22.015",
    transform: "translate(1637.049 919.566)",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    strokeWidth: "3"
  }), n.createElement("line", {
    x1: "22.015",
    transform: "translate(1626.041 930.574)",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    strokeWidth: "3"
  }), n.createElement("path", {
    d: "M728.456,559.625l3.888-3.887,3.885,3.885",
    transform: "translate(904.603 361.262)",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "3"
  }), n.createElement("path", {
    d: "M736.229,568.465l-3.888,3.888-3.885-3.885",
    transform: "translate(904.603 371.172)",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "3"
  }), n.createElement("path", {
    d: "M735.8,561.184l3.888,3.888-3.885,3.885",
    transform: "translate(910.317 365.503)",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "3"
  }), n.createElement("path", {
    d: "M727.813,568.957l-3.888-3.888,3.885-3.885",
    transform: "translate(901.075 365.503)",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "3"
  })));
}

var A = n.memo(function (e) {
  return n.useEffect(function () {
    if (document.querySelector(".key-board-drag-handle")) {
      var e = document.querySelector(".key-board-drag-handle");
      n = (t = e).parentNode, t.onmousedown = function (e) {
        var t = e.clientX - n.offsetLeft,
            r = e.clientY - n.offsetTop;
        document.onmousemove = function (e) {
          var a = e.clientY - r;
          n.style.left = e.clientX - t + "px", n.style.top = a + "px";
        }, document.onmouseup = function () {
          u.emit("updateBound"), document.onmousemove = null, document.onmouseup = null;
        };
      }, t.ontouchstart = function (e) {
        var t = e.touches[0].pageX - n.offsetLeft,
            r = e.touches[0].pageY - n.offsetTop;
        document.ontouchmove = function (e) {
          var a = e.touches[0].pageY - r;
          n.style.left = e.touches[0].pageX - t + "px", n.style.top = a + "px";
        }, document.ontouchend = function () {
          u.emit("updateBound"), document.ontouchmove = null, document.ontouchend = null;
        };
      };
    }

    var t, n;
  }, []), r.createElement("div", {
    className: "key-board-drag-handle",
    style: {
      color: e.color
    }
  }, r.createElement("span", null, e.dargHandleText), r.createElement(_, {
    fill: "none",
    stroke: e.color
  }));
}),
    W = {
  line1: ["[", "]", "{", "}", "+", "-", "*", "/", "%", "="],
  line2: ["_", "\u2014", "|", "~", "^", "\u300A", "\u300B", "$", "&"],
  line3: ["#+=", "\u2026\u2026", ",", "?", "!", ".", "\u2019", "'", "delete"]
},
    R = {
  line1: ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
  line2: ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
  line3: ["upper", "z", "x", "c", "v", "b", "n", "m", "delete"]
},
    F = {
  line1: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
  line2: ["-", "/", ":", "(", ")", "\xA5", "@", "\u201C", "\u201D"],
  line3: ["#+=", "\u3002", "\uFF0C", "\u3001", "\uFF1F", "\uFF01", ".", ";", "delete"]
},
    H = [{
  data: ".?123",
  type: "change2num"
}, {
  data: "",
  type: "change2lang"
}, {
  data: " ",
  type: "space"
}, {
  data: "",
  type: "close"
}],
    P = "",
    U = function (e, a) {
  var o = e.translate,
      i = e.trigger,
      c = e.change,
      s = n.useContext(l),
      d = s.modeList,
      h = s.handApi,
      f = s.closeKeyBoard,
      m = n.useState([R.line1, R.line2, R.line3]),
      p = m[0],
      y = m[1],
      v = n.useState([]),
      k = v[0],
      w = v[1],
      E = n.useState(!1),
      b = E[0],
      x = E[1],
      L = n.useState(!1),
      N = L[0],
      S = L[1],
      C = n.useState(!1),
      z = C[0],
      j = C[1],
      M = n.useState(!0),
      B = M[0],
      q = M[1];

  function T(e) {
    var t = e.data,
        n = e.type;

    switch (n) {
      case "close":
        P = "", f();
        break;

      case "upper":
        P = "", x(!b);
        break;

      case "change2lang":
        var r = !B;
        q(r), z || N || u.emit("keyBoardChange", r ? "CN" : "EN");
        break;

      case "change2num":
        var a = !z;

        if (j(a), S(!1), a) {
          u.emit("keyBoardChange", "number");
          var l = JSON.parse(JSON.stringify(F.line3));
          d.find(function (e) {
            return "symbol" === e;
          }) || (l.shift(), l.unshift("+")), y([F.line1, F.line2, l]);
        } else u.emit("keyBoardChange", B ? "CN" : "EN"), y([R.line1, R.line2, R.line3]);

        break;

      case "#+=":
        var s = !N;
        S(!N), s ? (u.emit("keyBoardChange", "symbol"), y([W.line1, W.line2, W.line3])) : (u.emit("keyBoardChange", "number"), y([F.line1, F.line2, F.line3]));
        break;

      case "handwrite":
      case "delete":
        B && "delete" === n && P ? (P = P.substr(0, P.length - 1), o(P)) : ("handwrite" === n && u.emit("keyBoardChange", "handwrite"), i({
          data: t,
          type: n
        }));
        break;

      default:
        !B || z || N ? c(t) : (o(P + t), P += t);
    }
  }

  return n.useEffect(function () {
    w(JSON.parse(JSON.stringify(H))), d.find(function (e) {
      return "handwrite" === e;
    }) && h && w(function (e) {
      return e.splice(2, 0, {
        data: "",
        type: "handwrite"
      }), e;
    }), u.on("resultReset", function () {
      P = "";
    });
  }, []), n.useImperativeHandle(a, function () {
    return {
      keyButtonTrigger: function (e) {
        T(e);
      }
    };
  }), r.createElement("div", {
    className: "default-key-board"
  }, p.map(function (e, n) {
    return r.createElement("div", {
      className: t("line", "line" + (n + 1)),
      key: n
    }, e.map(function (e) {
      return r.createElement(g, {
        isUpper: b,
        key: e,
        type: e,
        data: e,
        isSymbol: N,
        click: T
      });
    }));
  }), r.createElement("div", {
    className: "line line4"
  }, k.map(function (e, t) {
    return r.createElement(g, {
      key: t,
      type: e.type,
      data: e.data,
      isCn: B,
      isNum: z,
      click: T
    });
  })));
},
    Y = n.forwardRef(U),
    D = [],
    G = null,
    I = function (e, i) {
  var l = e.autoChange,
      d = void 0 === l || l,
      h = e.color,
      f = void 0 === h ? "#eaa050" : h,
      m = e.modeList,
      p = void 0 === m ? ["handwrite", "symbol"] : m,
      g = e.blurHide,
      y = void 0 === g || g,
      v = e.showHandleBar,
      k = void 0 === v || v,
      w = e.closeOnClickModal,
      E = void 0 === w || w,
      b = e.dargHandleText,
      x = void 0 === b ? "\u5C06\u952E\u76D8\u62D6\u5230\u60A8\u559C\u6B22\u7684\u4F4D\u7F6E" : b,
      L = e.animateClass,
      N = void 0 === L ? "move-bottom-to-top" : L,
      S = e.transitionTime,
      C = void 0 === S ? 300 : S,
      z = e.handApi,
      j = e.modal,
      M = e.keyChange,
      B = e.onChange,
      q = e.closed,
      _ = e.modalClick,
      W = n.useState(!1),
      R = W[0],
      F = W[1],
      H = n.useState("default"),
      P = H[0],
      U = H[1],
      I = n.useState({}),
      X = I[0],
      J = I[1],
      K = n.useRef();

  function V() {
    E && Q(), _ && _();
  }

  function Z() {
    z && (a.defaults.baseURL = z, a.defaults.timeout = 15000, a.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8"), document.querySelectorAll("input").forEach(function (e) {
      null !== e.getAttribute("data-mode") && (D.push(e), e.addEventListener("focus", $), y && e.addEventListener("blur", Q));
    });
  }

  function $(e) {
    F(!0), function (e) {
      var t, n;

      switch (u.emit("keyBoardChange", "CN"), e) {
        case "en":
          U("default"), null == (t = K.current) || t.keyButtonTrigger({
            data: "",
            type: "change2lang"
          });
          break;

        case "number":
          U("default"), null == (n = K.current) || n.keyButtonTrigger({
            data: ".?123",
            type: "change2num"
          });
          break;

        case "handwrite":
          null != p && p.find(function (e) {
            return "handwrite" === e;
          }) && z ? (U("handwrite"), u.emit("keyBoardChange", "handwrite")) : U("default");
          break;

        case "symbol":
          var r, a;
          U("default"), null != p && p.find(function (e) {
            return "symbol" === e;
          }) && (null == (r = K.current) || r.keyButtonTrigger({
            data: ".?123",
            type: "change2num"
          }), null == (a = K.current) || a.keyButtonTrigger({
            data: "#+=",
            type: "#+="
          }));
          break;

        default:
          U("default");
      }
    }((G = e.target).getAttribute("data-mode")), document.querySelector(".key-board-modal") && (document.querySelector(".key-board-modal").style.display = "block");
  }

  function Q() {
    G && G.blur(), G = null, F(!1), q && q(), U("default"), J({}), document.querySelector(".key-board-modal") && (document.querySelector(".key-board-modal").style.display = "none");
  }

  function ee(e) {
    switch (e.type) {
      case "handwrite":
        U("handwrite");
        break;

      case "delete":
        if (!G) return;
        var t = G.value.substr(0, G.value.length - 1);
        d && (G.value = t), B && B(t, G.getAttribute("data-prop") || G);
    }
  }

  function te(e) {
    if (G) {
      var t = G.value + e;
      d && (G.value = t), B && B(t, G.getAttribute("data-prop") || G), M && M(e, G.getAttribute("data-prop") || G);
    }
  }

  return n.useEffect(function () {
    return j && function () {
      var e;

      if (document.querySelector(".key-board-modal")) {
        var t;
        null == (t = document.querySelector(".key-board-modal")) || t.addEventListener("click", V);
      } else {
        var n = document.createElement("div");
        n.className = "key-board-modal", n.style.display = "none", null == (e = document.querySelector("body")) || e.appendChild(n), n.addEventListener("click", V);
      }
    }(), Z(), u.on("resultReset", function () {
      J({});
    }), function () {
      var e;
      null == (e = document.querySelector(".key-board-modal")) || e.removeEventListener("click", V), D.forEach(function (e) {
        e.removeEventListener("focus", $), e.removeEventListener("blur", Q);
      });
    };
  }, []), n.useImperativeHandle(i, function () {
    return {
      reSignUp: function () {
        Z();
      },
      getCurrentInput: function () {
        return G;
      }
    };
  }), r.createElement(o.CSSTransition, {
    in: R,
    classNames: t(N),
    timeout: C,
    unmountOnExit: !0
  }, r.createElement(c, {
    value: {
      color: f,
      handApi: z,
      modeList: p,
      transitionTime: C,
      closeKeyBoard: function () {
        Q();
      },
      changeDefaultBoard: function () {
        U("default"), u.emit("resultReset");
      }
    }
  }, r.createElement("div", {
    className: "key-board",
    onMouseDown: function (e) {
      e.preventDefault();
    }
  }, r.createElement("div", {
    className: "key-board-container"
  }, r.createElement(s, {
    resultVal: X,
    change: te
  }), r.createElement("div", {
    className: "key-board-area"
  }, "default" === P && r.createElement(Y, {
    ref: K,
    translate: function (e) {
      if (G) {
        var t = new RegExp("^" + e + "\\w*"),
            n = Object.keys(O).filter(function (e) {
          return t.test(e);
        }).sort();
        J({
          code: e,
          value: e ? n.length > 1 ? n.reduce(function (e, t) {
            return e + O[t];
          }, "") : O[n[0]] : ""
        }), M && M(e, G.getAttribute("data-prop") || G);
      }
    },
    change: te,
    trigger: ee
  }), "handwrite" === P && r.createElement(T, {
    trigger: ee
  }))), k && r.createElement(A, {
    color: f,
    dargHandleText: x
  }))));
};

exports.default = n.forwardRef(I);
export default exports;